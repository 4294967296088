import { ENDPOINTS } from '../../consts/endpoints';
import { URLS } from '../../consts/urls';
import { CreateProjectData } from '../../interfaces/projects';
import { projectCreateDataAdapter } from '../adapters';
import { authorizatedFetcher } from './authorizatedFetcher';

export const createProject = async (data: CreateProjectData) => {
  await authorizatedFetcher(
    `${URLS.MANUSCRIPTIFY_API_URL}${ENDPOINTS.ADD_PROJECT}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(projectCreateDataAdapter(data)),
    }
  );
};
