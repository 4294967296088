import { To, useNavigate } from 'react-router-dom';
import { ROUTES } from '../consts/routes';
import { ArrowForwardCircle } from './icons/ArrowForwardCircle';
import { Quicklink } from './Quicklink';

interface NavbarProps {
  title: string;
  quicklinkLabel: string;
  redirectTo: To;
}

export const Navbar = ({
  title,
  quicklinkLabel,
  redirectTo,
}: NavbarProps) => {
  const navigate = useNavigate();
  // ROUTES;
  return (
    <nav className="flex justify-between border-b px-5 py-4 dark:border-violet-1 dark:bg-darkblue-2">
      <h1>{title}</h1>
      <Quicklink
        icon={ArrowForwardCircle}
        label={quicklinkLabel}
        onClick={() => navigate(redirectTo)}
      />
    </nav>
  );
};
