import { createMemoryRouter } from 'react-router-dom';
import { Projects } from '../components/projects/Projects';
import { Project } from '../components/project/Project';
import { Browse } from '../components/browse/Browse';
import { AddProject } from '../components/AddProject';
import { ProjectSettings } from '../components/ProjectSettings';
import { Login } from '../components/Login';
import { ROUTES } from '../consts/routes';

export const loginRouter = createMemoryRouter(
  [
    { path: ROUTES.LOGIN, element: <Login /> }
  ],
  { initialEntries: [ROUTES.LOGIN] }
);

export const mainRouter = createMemoryRouter(
  [
    { path: ROUTES.PROJECTS, element: <Projects /> },
    { path: ROUTES.PROJECT, element: <Project /> },
    { path: ROUTES.BROWSE, element: <Browse /> },
    { path: ROUTES.ADD_PROJECT, element: <AddProject /> },
    { path: ROUTES.PROJECT_SETTINGS, element: <ProjectSettings /> },
    { path: ROUTES.LOGIN, element: <Login /> },
    { path: '/*', element: <Projects /> },
    /*
    {
      path: PANEL_ROUTES.WELCOME_PAGE,
      element: <WelcomePage />,
    },
    */
  ],
  { initialEntries: [ROUTES.PROJECTS] }
);
