import { ENDPOINTS } from '../../consts/endpoints';
import { URLS } from '../../consts/urls';
import { ProjectDeleteResponse } from '../../interfaces/api';
import { authorizatedFetcher } from './authorizatedFetcher';

export const removeProject = async (projectId: string) => {
  const body = {
    project_id: projectId,
  };
  const response = await authorizatedFetcher(
    `${URLS.MANUSCRIPTIFY_API_URL}${ENDPOINTS.REMOVE_PROJECT}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  );
  const data: ProjectDeleteResponse = await response.json();
  return data;
};
