export const Document = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7188 8.75H11.25C10.7527 8.75 10.2758 8.55246 9.92417 8.20082C9.57254 7.84919 9.375 7.37228 9.375 6.875V1.40625C9.375 1.36481 9.35854 1.32507 9.32924 1.29576C9.29993 1.26646 9.26019 1.25 9.21875 1.25H5.625C4.96196 1.25 4.32607 1.51339 3.85723 1.98223C3.38839 2.45107 3.125 3.08696 3.125 3.75V16.25C3.125 16.913 3.38839 17.5489 3.85723 18.0178C4.32607 18.4866 4.96196 18.75 5.625 18.75H14.375C15.038 18.75 15.6739 18.4866 16.1428 18.0178C16.6116 17.5489 16.875 16.913 16.875 16.25V8.90625C16.875 8.86481 16.8585 8.82507 16.8292 8.79576C16.7999 8.76646 16.7602 8.75 16.7188 8.75Z"
      fill="#A0FFF9"
    />
    <path
      d="M7.91667 14.583L6.25 10.833M13.3333 13.7413V13.7497M10.8333 13.7497V13.758"
      stroke="#282757"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.3758 7.36686L10.7582 1.74928C10.7473 1.73842 10.7334 1.73103 10.7183 1.72805C10.7032 1.72506 10.6875 1.7266 10.6733 1.73249C10.659 1.73838 10.6469 1.74834 10.6383 1.76113C10.6297 1.77391 10.6251 1.78896 10.625 1.80436V6.87506C10.625 7.04082 10.6908 7.19979 10.8081 7.317C10.9253 7.43421 11.0842 7.50006 11.25 7.50006H16.3207C16.3361 7.5 16.3511 7.49538 16.3639 7.48679C16.3767 7.4782 16.3867 7.46603 16.3926 7.45179C16.3985 7.43756 16.4 7.4219 16.397 7.40679C16.394 7.39168 16.3866 7.37778 16.3758 7.36686Z"
      fill="#A0FFF9"
    />
  </svg>
);
