import Cookies from 'universal-cookie';
import { HTTP_RESPONSES } from '../../consts/httpResponses';
import { TOKEN_ERROR_DETAIL } from '../../consts/tokenErrorDetail';
import { getToken } from '../getToken';
import { refreshToken } from './refreshToken';

export const authorizatedFetcher = async (
  url: string,
  options: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }
): Promise<Response> => {
  const token = await getToken();

  if (Boolean(token)) {
    const headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };
    const response = await fetch(url, {
      ...options,
      headers,
    });

    if (response.status === HTTP_RESPONSES.UNAUTHORIZED) {
      const data = await response.json();

      if (data.detail === TOKEN_ERROR_DETAIL.TOKEN_INVALID) {
        throw new Error('Token invalid (cannot be refreshed)');
      }

      if (data.detail === TOKEN_ERROR_DETAIL.TOKEN_EXPIRED) {
        try {
          const response = await refreshToken();
          if (response.ok) {
            const { bearer } = await response.json();
            const cookies = new Cookies(null, { path: '/' });
            cookies.set('token', bearer);
            return await authorizatedFetcher(url, options);
          }
        } catch (error) {
          throw new Error('Error when refreshing token');
        }
      }
    }

    return response;
  }

  throw new Error('No token');
};
