import { Footer } from './Footer';
import { FolderTreeNode } from '../../interfaces/projects';
import {
  checkItemWithoutFamily,
  convertFolderTreeNodesToTreeItems,
  getTreeItem,
} from '../../utils/folderTree';
import { useState } from 'react';
import { SortableTree } from 'dnd-kit-sortable-tree';

export const FolderTree = ({ data }: { data: FolderTreeNode }) => {
  const [items, setItems] = useState(
    convertFolderTreeNodesToTreeItems(data.children || [])
  );

  const handleCheckItem = (id: string) => {
    const newItems = checkItemWithoutFamily(id, items);
    setItems(newItems);
  };

  return (
    <>
      <section className="px-5 max-h-[calc(100%-250px)] overflow-auto">
        <div className="rounded-lg border py-1 dark:border-violet-1 dark:bg-darkblue-2">
          <SortableTree
            items={items}
            onItemsChanged={setItems}
            TreeItemComponent={getTreeItem(handleCheckItem, false)}
            indentationWidth={33}
            disableSorting={true}
          />
        </div>
      </section>
      <Footer items={items} />
    </>
  );
};
