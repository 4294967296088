import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, ReactElement, ReactNode } from 'react';
import { ChevronDown } from './icons/ChevronDown';
import { ChevronUp } from './icons/ChevronUp';
import { Loading } from './icons/Loading';

interface MenuItem {
  label: string;
  handleClick: React.MouseEventHandler<HTMLDivElement> | undefined;
}

type SplitButtonProps = {
  menuItems: MenuItem[];
  children?: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  buttonType?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
};

export const SplitButton = ({
  children,
  onClick,
  disabled,
  buttonType = 'button',
  menuItems,
  isLoading,
}: SplitButtonProps): ReactElement => {
  return (
    <div className="flex items-stretch">
      <button
        className={classNames(
          'flex w-full items-center justify-center rounded-full rounded-r-none py-3 px-5 text-sm font-semibold uppercase leading-5 disabled:cursor-not-allowed dark:bg-primary dark:text-darkblue-1 dark:hover:bg-primary-hover dark:active:bg-primary-pressed dark:disabled:bg-gray text-nowrap'
        )}
        onClick={onClick}
        type={buttonType}
        disabled={disabled || isLoading}
      >
        {isLoading ? (
          <span className="ml-6 animate-spin">
            <Loading />
          </span>
        ) : (
          children
        )}
      </button>

      <Menu as="div">
        {({ open }) => (
          <>
            <Menu.Button
              className="ml-[1px] h-full rounded-full rounded-l-none py-2 px-5 font-semibold uppercase leading-5 disabled:cursor-not-allowed disabled:border-gray dark:bg-primary dark:text-darkblue-1 dark:hover:bg-primary-hover dark:active:bg-primary-pressed dark:disabled:bg-gray"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                e.stopPropagation()
              }
              disabled={disabled || open || isLoading}
            >
              {open ? ChevronUp('#24234E') : ChevronDown('#24234E')}
            </Menu.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="ring-black absolute right-28 bottom-full z-10 -mb-3 origin-top-right rounded-md bg-darkblue-1 px-4 pb-3 shadow-lg ring-1 ring-opacity-5 focus:outline-none">
                {menuItems.map((item) => (
                  <Menu.Item key={item.label}>
                    {() => (
                      <div className="pt-3" onClick={item.handleClick}>
                        <span className="medium-14 hover:cursor-pointer hover:underline">
                          {item.label}
                        </span>
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};
