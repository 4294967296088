import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../consts/routes';
import { Navbar } from './Navbar';
import { useEffect, useState } from 'react';
import { Button } from './Button';
import { UpdateProjectData } from '../interfaces/projects';
import { Input } from './Input';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getProjectSettings } from '../utils/api/getProjectSettings';
import { PROJECT_SETTINGS } from '../consts/queryKeys';
import { Spinner } from './icons/Spinner';
import { updateProject } from '../utils/api/updateProject';
import { queryClient } from '../utils/queryClients';
import { Quicklink } from './QuicklinkHideshow';
import { ArrowBackCircle } from './icons/ArrowBackCircle';
import { ChevronUp } from './icons/ChevronUp';
import { ChevronDown } from './icons/ChevronDown';
import { ErrorPanel } from './ErrorPanel';
import { MessagePanel } from './MessagePanel';
import {
  chapterDepthValidation,
  stringIsNotEmpty,
} from '../utils/validation';
import { truncateToThreeWords } from '../utils/truncateToThreeWords';

export const ProjectSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const projectId: string | undefined = location.state?.projectId;

  const {
    data: projectSettings,
    isPending,
    isError,
  } = useQuery({
    queryKey: [PROJECT_SETTINGS, projectId],
    queryFn: () => getProjectSettings(projectId)
  });

  const {
    mutate,
    isPending: isUpdating,
    isSuccess: isMutateSuccess,
  } = useMutation({
    mutationFn: updateProject,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  const [title, setTitle] = useState('');
  const [shortTitle, setShortTitle] = useState('');
  const [pseudonym, setPseudonym] = useState('');
  const [genre, setGenre] = useState('');
  const [category, setCategory] = useState('');
  const [chapterDepth, setChapterDepth] = useState('1');
  const [showAdvanced, setShowAdvanced] = useState(false);

  const [titleError, setTitleError] = useState<string | null>(null);
  const [shortTitleError, setShortTitleError] = useState<string | null>(null);
  const [pseudonymError, setPseudonymError] = useState<string | null>(null);
  const [genreError, setGenreError] = useState<string | null>(null);
  const [categoryError, setCategoryError] = useState<string | null>(null);
  const [chapterDepthError, setChapterDepthError] = useState<string | null>(
    null
  );

  const toggleAdvanced = () => setShowAdvanced(!showAdvanced)

  useEffect(() => {
    if (projectSettings) {
      setTitle(projectSettings.title);
      setShortTitle(projectSettings.shortTitle);
      setPseudonym(projectSettings.pseudonym);
      setGenre(projectSettings.genre);
      setCategory(projectSettings.category);
      setChapterDepth(String(projectSettings.chapterDepth));
    }
  }, [projectSettings]);

  if (isPending) return <LoadingView />;
  if (isError) return <ErrorView />;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const shortTitle = truncateToThreeWords(value);
    switch (name) {
      case 'title':
        setTitle(value);
        setShortTitle(shortTitle);
        if (stringIsNotEmpty(value)) setTitleError(null);
        if (stringIsNotEmpty(shortTitle)) setShortTitleError(null);
        break;
      case 'shortTitle':
        setShortTitle(shortTitle);
        if (truncateToThreeWords(shortTitle)) setTitleError(null);
        break;
      case 'pseudonym':
        setPseudonym(value);
        if (stringIsNotEmpty(value)) setPseudonymError(null);
        break;
      case 'genre':
        setGenre(value);
        if (stringIsNotEmpty(value)) setGenreError(null);
        break;
      case 'category':
        setCategory(value);
        if (stringIsNotEmpty(value)) setCategoryError(null);
        break;
      case 'chapterDepth':
        setChapterDepth(value);
        if (chapterDepthValidation(value)) setChapterDepthError(null);
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    if (!stringIsNotEmpty(title)) setTitleError('Title is required');
    if (!stringIsNotEmpty(shortTitle))
      setShortTitleError('Short title is required');
    if (!stringIsNotEmpty(pseudonym))
      setPseudonymError('Pseudonym is required');
    if (!stringIsNotEmpty(genre)) setGenreError('Genre is required');
    if (!stringIsNotEmpty(category)) setCategoryError('Category is required');
    if (!chapterDepthValidation(chapterDepth))
      setChapterDepthError('Chapter must be a number between 0 and 4');
    if (
      !stringIsNotEmpty(title) ||
      !stringIsNotEmpty(pseudonym) ||
      !stringIsNotEmpty(genre) ||
      !stringIsNotEmpty(category) ||
      !chapterDepthValidation(chapterDepth)
    ) {
      return false;
    }
    return true;
  };

  const AdvancedSettings = () => {
    return (
      <div>
        { showAdvanced ? <AdvancedOpen /> : <AdvancedClosed /> }
      </div>
    );
  };

  const AdvancedClosed = () => (
    <Quicklink
      label="Advanced settings"
      icon={ChevronDown}
      onClick={toggleAdvanced}
      iconPosition="right"
      size="medium"
    />
  );

  const AdvancedOpen = () => (
    <>
      <Quicklink
        label="Advanced settings"
        icon={ChevronUp}
        onClick={toggleAdvanced}
        iconPosition="right"
      />
      <Input
        name="chapterDepth"
        label="Chapter depth"
        value={chapterDepth}
        onChange={handleInputChange}
        error={chapterDepthError}
      />
    </>
  );

  const handleUpdateProject = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;
    const data: UpdateProjectData = {
      category,
      genre,
      pseudonym,
      title,
      shortTitle,
      chapterDepth: Number(chapterDepth),
      projectId: projectSettings.projectId,
    };
    mutate(data);
  };

  return (
    <>
      <Navbar
        title="Update project"
        redirectTo={ROUTES.PROJECTS}
        quicklinkLabel="Projects"
      />
      {isMutateSuccess && (
        <MessagePanel message="Project updated successfully." />
      )}
      <div className="max-h-[calc(100%-180px)] m-5 overflow-auto">
        <h2 className="mb-4">Updating project: {projectSettings.title}</h2>
        <form
          onSubmit={handleUpdateProject}
          className="mt-8 flex flex-col gap-4"
        >
          <Input
            name="title"
            label="Title"
            value={title}
            onChange={handleInputChange}
            error={titleError}
          />
          <Input
            name="shortTitle"
            label="Short title"
            value={shortTitle}
            onChange={handleInputChange}
            error={shortTitleError}
          />
          <Input
            name="pseudonym"
            label="Pseudonym"
            value={pseudonym}
            onChange={handleInputChange}
            error={pseudonymError}
          />
          <Input
            name="genre"
            label="Genre"
            value={genre}
            onChange={handleInputChange}
            error={genreError}
          />
          <Input
            name="category"
            label="Category"
            value={category}
            onChange={handleInputChange}
            error={categoryError}
          />
          <AdvancedSettings />
          <div className="mt-6">
            <Button buttonType="submit" isLoading={isUpdating}>
              Update project
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

const LoadingView = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar
        title="Update project"
        redirectTo={ROUTES.PROJECTS}
        quicklinkLabel="Projects"
      />
      <div className="m-5">
        <h2 className="mb-4">Updating project</h2>
      </div>
      <div className="mt-12 flex justify-center">
        <Spinner />
      </div>
    </>
  );
};

const ErrorView = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar
        title="Update project"
        redirectTo={ROUTES.PROJECTS}
        quicklinkLabel="Projects"
      />
      <div className="m-5">
        <h2 className="mb-4">Updating project</h2>
      </div>
      <ErrorPanel error="Sorry, we were unable to fetch project settings." />
    </>
  );
};
