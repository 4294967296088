import React from 'react';
import classNames from 'classnames';
import Cookies from 'universal-cookie';
import { RouterProvider } from 'react-router-dom';
import { getIsTokenExpired } from './utils/getIsTokenExpired';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './utils/queryClients';
import { mainRouter, loginRouter } from './utils/routers';
import { ROUTES } from './consts/routes';
import { ENDPOINTS } from './consts/endpoints';
import { URLS } from './consts/urls';
import { PanelLayout } from './components/PanelLayout';
import './output.css';

async function handleAuth() {
  var queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (urlParams.get('authorizated')) {
    const cookies = new Cookies(null, { path: '/' });
    let sid = cookies.get('sid');
    let body = {
      sid: sid,
    };
    let headers = {
      'Content-Type': 'application/json',
    };
    let response = await fetch(
      `${URLS.MANUSCRIPTIFY_API_URL}${ENDPOINTS.VALIDATE_SESSION}`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      }
    );
    if (response.ok) {
      let { bearer } = await response.json();
      cookies.set('token', bearer);
      window.location.replace(ROUTES.ROOT);
    }
  }
}

handleAuth();
const router = getIsTokenExpired() ? loginRouter : mainRouter;

// maybe put an error boundary here to smooth the login-redirect workflow

function App() {
  return (
    <div
      className={classNames(`dark h-full w-[370px] md:w-[640px] border-x border-violet-1`)}
    >
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <PanelLayout>
            <RouterProvider router={router} />
          </PanelLayout>
        </QueryClientProvider>
      </React.StrictMode>
    </div>
  );
}

export default App;
