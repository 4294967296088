export const GoogleDocument = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.58301 0.833008C3.89265 0.833008 3.33301 1.39265 3.33301 2.08301V17.9163C3.33301 18.6067 3.89265 19.1663 4.58301 19.1663H15.4163C16.1067 19.1663 16.6663 18.6067 16.6663 17.9163V5.83301L11.6663 0.833008H4.58301Z"
      fill="#4E8DF5"
    />
    <path
      d="M4.58301 19.1663C3.89265 19.1663 3.33301 18.6067 3.33301 17.9163V17.708C3.33301 18.3984 3.89265 18.958 4.58301 18.958H15.4163C16.1067 18.958 16.6663 18.3984 16.6663 17.708V17.9163C16.6663 18.6067 16.1067 19.1663 15.4163 19.1663H4.58301Z"
      fill="#417EE9"
    />
    <path
      d="M4.58301 0.833008C3.89265 0.833008 3.33301 1.39265 3.33301 2.08301V2.29134C3.33301 1.60099 3.89265 1.04134 4.58301 1.04134H11.8747L11.6663 0.833008H4.58301Z"
      fill="#639AF6"
    />
    <path
      d="M16.6671 10.1045V5.83366L15.9379 5.10449H12.4215C12.1431 5.10449 12.0037 5.4411 12.2006 5.63796L16.6671 10.1045Z"
      fill="url(#paint0_linear_5_6589)"
    />
    <path
      d="M11.667 0.833008L16.667 5.83301H12.917C12.2266 5.83301 11.667 5.27336 11.667 4.58301V0.833008Z"
      fill="#A6C5FA"
    />
    <path d="M13.3337 10H6.66699V10.8333H13.3337V10Z" fill="white" />
    <path d="M13.3337 11.6667H6.66699V12.5H13.3337V11.6667Z" fill="white" />
    <path d="M6.66699 13.3333H13.3337V14.1667H6.66699V13.3333Z" fill="white" />
    <path d="M11.667 15H6.66699V15.8333H11.667V15Z" fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear_5_6589"
        x1="14.1671"
        y1="10.1045"
        x2="14.1671"
        y2="5.00033"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4E8DF5" />
        <stop offset="1" stopColor="#3D6ACD" />
      </linearGradient>
    </defs>
  </svg>
);
