import { ENDPOINTS } from '../../consts/endpoints';
import { URLS } from '../../consts/urls';
import { getToken } from '../getToken';

interface Token {
  bearer: string;
}

export const refreshToken = async () => {
  const token = await getToken();

  if (Boolean(token)) {
    const body: Token = {
      bearer: token,
    };
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    const response = await fetch(
      `${URLS.MANUSCRIPTIFY_API_URL}${ENDPOINTS.REFRESH_TOKEN}`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      }
    );
    return response;
  }
  throw new Error('No token');
};
