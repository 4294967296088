import { TreeItems } from 'dnd-kit-sortable-tree';
import { MinimalTreeItemData } from '../interfaces/folderTree';

export interface Folder {
  folderId: string;
  folderName: string;
}

export const findAllCheckedFoldersIds = (
  items: TreeItems<MinimalTreeItemData>
) => {
  const checkedIds: Folder[] = [];
  items.forEach((item) => {
    if (item.isCheck) {
      checkedIds.push({ folderId: item.folderId, folderName: item.name });
    }
    if (item.children) {
      const childCheckedIds = findAllCheckedFoldersIds(item.children);
      checkedIds.push(...childCheckedIds);
    }
  });
  return checkedIds;
};
