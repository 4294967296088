interface ElipsisProps {
  size?: 'small' | 'medium';
}

export const Elipsis = ({ size = 'medium' }: ElipsisProps) => (
  <svg
    width={size === 'small' ? 16 : 20}
    height={size === 'small' ? 16 : 20}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 9.5C8.82843 9.5 9.5 8.82843 9.5 8C9.5 7.17157 8.82843 6.5 8 6.5C7.17157 6.5 6.5 7.17157 6.5 8C6.5 8.82843 7.17157 9.5 8 9.5Z"
      fill="#A0FFF9"
    />
    <path
      d="M9.4707 13C9.4707 13.8122 8.81225 14.4707 8 14.4707C7.18775 14.4707 6.5293 13.8122 6.5293 13C6.5293 12.1878 7.18775 11.5293 8 11.5293C8.81225 11.5293 9.4707 12.1878 9.4707 13Z"
      fill="#A0FFF9"
      stroke="#A0FFF9"
      strokeWidth="0.0585938"
    />
    <path
      d="M8 4.5C8.82843 4.5 9.5 3.82843 9.5 3C9.5 2.17157 8.82843 1.5 8 1.5C7.17157 1.5 6.5 2.17157 6.5 3C6.5 3.82843 7.17157 4.5 8 4.5Z"
      fill="#A0FFF9"
    />
  </svg>
);
