import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../consts/routes';
import { Navbar } from './Navbar';
import { Folder } from '../utils/findAllCheckedIds';
import { useEffect, useState } from 'react';
import { Button } from './Button';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../utils/queryClients';
import { createProject } from '../utils/api/createProject';
import { CreateProjectData } from '../interfaces/projects';
import { Input } from './Input';
import { getUserProfile } from '../utils/api/getUserProfile';
import { USER_PROFILE } from '../consts/queryKeys';
import { UserProfile } from '../interfaces/userProfile';
import { Quicklink } from './QuicklinkHideshow';
import { MessagePanel } from './MessagePanel';
import { ErrorPanel } from './ErrorPanel';
import { ChevronUp } from './icons/ChevronUp';
import { ChevronDown } from './icons/ChevronDown';
import {
  chapterDepthValidation,
  stringIsNotEmpty,
} from '../utils/validation';
import { truncateToThreeWords } from '../utils/truncateToThreeWords';

export const AddProject = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const folders: Folder[] = location.state?.selectedFolders;

  const [currentFolderIndex, setCurrentFolderIndex] = useState(0);
  const currentFolder = folders.at(currentFolderIndex);

  const [shortTitle, setShortTitle] = useState(
    (currentFolder?.folderName &&
      truncateToThreeWords(currentFolder.folderName)) ||
      ''
  );
  const [title, setTitle] = useState(currentFolder?.folderName || '');
  const [pseudonym, setPseudonym] = useState('');
  const [genre, setGenre] = useState('');
  const [category, setCategory] = useState('');
  const [chapterDepth, setChapterDepth] = useState('');
  const [showAdvanced, setShowAdvanced] = useState(false);

  const [titleError, setTitleError] = useState<string | null>(null);
  const [shortTitleError, setShortTitleError] = useState<string | null>(null);
  const [pseudonymError, setPseudonymError] = useState<string | null>(null);
  const [genreError, setGenreError] = useState<string | null>(null);
  const [categoryError, setCategoryError] = useState<string | null>(null);
  const [chapterDepthError, setChapterDepthError] = useState<string | null>(
    null
  );

  const toggleAdvanced = () => setShowAdvanced(!showAdvanced)

  const { mutate, isPending, isError } = useMutation({
    mutationFn: createProject,
    onSuccess: () => {
      if (currentFolderIndex < folders.length - 1) {
        setCurrentFolderIndex(currentFolderIndex + 1);
        return;
      }

      queryClient.invalidateQueries();
      navigate(ROUTES.PROJECTS);
    },
  });

  const { data } = useQuery({
    queryKey: [USER_PROFILE],
    queryFn:  getUserProfile
  });

  useEffect(() => {
    if (data && currentFolder) {
      const shortTitle = truncateToThreeWords(currentFolder.folderName);
      setTitle(currentFolder.folderName);
      setShortTitle(shortTitle);
      setPseudonym(data.defaultPseudonym || '');
      setGenre(data.defaultGenre || '');
      setCategory(data.defaultCategory || '');
      if (data.defaultChapterDepth === 0) {
        setChapterDepth('0');
      } else {
        setChapterDepth(((data.defaultChapterDepth as unknown) as string) || '1');
      }
    }
  }, [data, currentFolderIndex]);

  if (!currentFolder) {
    return (
      <>
        <Navbar
          title="Add project"
          redirectTo={ROUTES.BROWSE}
          quicklinkLabel="Browse"
        />
        <MessagePanel message="There are no folders to add." />
      </>
    );
  }

  const AdvancedSettings = () => {
    return (
      <div>
        { showAdvanced ? <AdvancedOpen /> : <AdvancedClosed /> }
      </div>
    );
  };

  const AdvancedClosed = () => (
    <Quicklink
      label="Advanced settings"
      icon={ChevronDown}
      onClick={toggleAdvanced}
      iconPosition="right"
    />
  );

  const AdvancedOpen = () => (
    <>
      <Quicklink
        label="Advanced settings"
        icon={ChevronUp}
        onClick={toggleAdvanced}
        iconPosition="right"
      />
      <Input
        name="chapterDepth"
        label="Chapter depth"
        value={chapterDepth}
        onChange={handleInputChange}
        error={chapterDepthError}
      />
    </>
  );


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const shortTitle = truncateToThreeWords(value);
    switch (name) {
      case 'title':
        setTitle(value);
        setShortTitle(shortTitle);
        if (stringIsNotEmpty(value)) setTitleError(null);
        if (stringIsNotEmpty(shortTitle)) setShortTitleError(null);
        break;
      case 'shortTitle':
        setShortTitle(shortTitle);
        if (truncateToThreeWords(shortTitle)) setTitleError(null);
        break;
      case 'pseudonym':
        setPseudonym(value);
        if (stringIsNotEmpty(value)) setPseudonymError(null);
        break;
      case 'genre':
        setGenre(value);
        if (stringIsNotEmpty(value)) setGenreError(null);
        break;
      case 'category':
        setCategory(value);
        if (stringIsNotEmpty(value)) setCategoryError(null);
        break;
      case 'chapterDepth':
        setChapterDepth(value);
        if (chapterDepthValidation(value)) setChapterDepthError(null);
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    if (!stringIsNotEmpty(title)) setTitleError('Title is required');
    if (!stringIsNotEmpty(shortTitle))
      setShortTitleError('Short title is required');
    if (!stringIsNotEmpty(pseudonym))
      setPseudonymError('Pseudonym is required');
    if (!stringIsNotEmpty(genre)) setGenreError('Genre is required');
    if (!stringIsNotEmpty(category)) setCategoryError('Category is required');
    if (!chapterDepthValidation(chapterDepth))
      setChapterDepthError('Chapter must be a number between 0 and 4');
    if (
      !stringIsNotEmpty(title) ||
      !stringIsNotEmpty(pseudonym) ||
      !stringIsNotEmpty(genre) ||
      !stringIsNotEmpty(category) ||
      !chapterDepthValidation(chapterDepth)
    ) {
      return false;
    }
    return true;
  };

  const handleAddProject = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;

    const data: CreateProjectData = {
      category,
      genre,
      pseudonym,
      title,
      shortTitle,
      projectId: currentFolder.folderId,
      chapterDepth: Number(chapterDepth),
    };
    mutate(data);
  };

  return (
    <>
      <Navbar
        title={`Add project${folders.length > 1 ? 's' : ''}`}
        redirectTo={ROUTES.BROWSE}
        quicklinkLabel="Browse"
      />
      {isError && (
        <ErrorPanel error="Sorry, we were not able to add project. Please try again later." />
      )}
      <div className="max-h-[calc(100%-150px)] m-5 overflow-auto">
        <h2 className="mb-4">
          Adding project{folders.length > 1 && 's'} from selected folder
          {folders.length > 1 && 's'}
        </h2>
        {folders.length > 1 && (
          <h3>
            Selected Folder: "{currentFolder.folderName}"{' '}
            {currentFolderIndex + 1}/{folders.length}
          </h3>
        )}

        <form onSubmit={handleAddProject} className="mt-8 flex flex-col gap-4">
          <Input
            name="title"
            label="Title"
            value={title}
            onChange={handleInputChange}
            error={titleError}
          />
          <Input
            name="shortTitle"
            label="Short title"
            value={shortTitle}
            onChange={handleInputChange}
            error={shortTitleError}
          />
          <Input
            name="pseudonym"
            label="Pseudonym"
            value={pseudonym}
            onChange={handleInputChange}
            error={pseudonymError}
          />
          <Input
            name="genre"
            label="Genre"
            value={genre}
            onChange={handleInputChange}
            error={genreError}
          />
          <Input
            name="category"
            label="Category"
            value={category}
            onChange={handleInputChange}
            error={categoryError}
          />
          <AdvancedSettings />
          <div className="mt-6">
            <Button buttonType="submit" isLoading={isPending}>
              Add project{' '}
              {folders.length > 1 &&
                currentFolderIndex < folders.length - 1 &&
                ' & go to next folder'}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
