export const LogoWithText = ({
  isSmall,
  isBig,
}: {
  isSmall?: boolean;
  isBig?: boolean;
}) => (
  <svg
    width={isSmall ? '126' : '168'}
    height={isSmall ? '18' : '24'}
    viewBox="0 0 168 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.0771 8.49966C63.7634 8.49966 62.4362 8.86757 61.4376 9.54717C61.1942 9.71284 61.1228 10.0309 61.2392 10.301L61.6365 11.2225C61.8211 11.6507 62.3868 11.7685 62.7995 11.5498C63.3772 11.2437 64.0523 11.0692 64.712 11.0692C66.1725 11.0692 66.8679 11.8774 66.8679 13.2658H64.712C61.8606 13.2658 60.6957 14.6335 60.6957 16.6021C60.6957 18.5293 61.9997 19.9798 64.1904 19.9798C65.5639 19.9798 66.5376 19.4411 67.0418 18.4257V19.1443C67.0418 19.5142 67.3424 19.8141 67.7132 19.8141H68.9089C69.2797 19.8141 69.5803 19.5142 69.5803 19.1443V13.4523C69.5803 10.0746 67.9285 8.49966 65.0771 8.49966ZM64.8685 17.804C63.9122 17.804 63.3384 17.2652 63.3384 16.457C63.3384 15.711 63.7383 15.1515 65.0076 15.1515H66.8679V16.2913C66.555 17.3067 65.7726 17.804 64.8685 17.804Z"
      fill="white"
    />
    <path
      d="M77.0492 8.49966C75.7799 8.49966 74.6846 9.01771 73.9543 9.97094V9.33518C73.9543 8.96529 73.6538 8.66544 73.283 8.66544H72.0351C71.6643 8.66544 71.3637 8.96529 71.3637 9.33518V19.1443C71.3637 19.5142 71.6643 19.8141 72.0351 19.8141H73.4047C73.7755 19.8141 74.076 19.5142 74.076 19.1443V14.3019C74.076 12.2504 75.0149 11.2972 76.3189 11.2972C77.5186 11.2972 78.2141 12.1261 78.2141 13.9289V19.1443C78.2141 19.5142 78.5147 19.8141 78.8854 19.8141H80.2551C80.6258 19.8141 80.9264 19.5142 80.9264 19.1443V13.4316C80.9264 10.0331 79.2573 8.49966 77.0492 8.49966Z"
      fill="white"
    />
    <path
      d="M90.0963 8.66544C89.7255 8.66544 89.4249 8.96529 89.4249 9.33518V14.1776C89.4249 16.2291 88.5208 17.1823 87.269 17.1823C86.0693 17.1823 85.3738 16.3534 85.3738 14.4884V9.33518C85.3738 8.96529 85.0733 8.66544 84.7025 8.66544H83.3329C82.9621 8.66544 82.6615 8.96529 82.6615 9.33518V14.965C82.6615 18.4049 84.3132 19.9798 86.6604 19.9798C87.808 19.9798 88.8512 19.4618 89.564 18.4878V19.1443C89.564 19.5142 89.8646 19.8141 90.2354 19.8141H91.4659C91.8367 19.8141 92.1373 19.5142 92.1373 19.1443V9.33518C92.1373 8.96529 91.8367 8.66544 91.4659 8.66544H90.0963Z"
      fill="white"
    />
    <path
      d="M97.6292 19.9798C100.428 19.9798 102.045 18.5086 102.045 16.4363C102.045 12.0432 96.3948 13.8875 96.3948 11.981C96.3948 11.4008 96.9164 10.9449 98.1334 10.9449C98.76 10.9449 99.4241 11.057 100.098 11.3635C100.49 11.5415 100.978 11.3942 101.135 10.9947L101.54 9.9635C101.653 9.67597 101.556 9.34452 101.281 9.20267C100.404 8.75061 99.2194 8.49966 98.1334 8.49966C95.4037 8.49966 93.8041 9.99167 93.8041 12.1053C93.8041 16.5607 99.4548 14.6956 99.4548 16.5192C99.4548 17.1409 98.9854 17.5346 97.7335 17.5346C96.8964 17.5346 95.9945 17.303 95.2438 16.9283C94.8429 16.7282 94.3195 16.8749 94.1571 17.2918L93.758 18.3162C93.6511 18.5906 93.7343 18.9061 93.9879 19.0567C94.8988 19.5977 96.2704 19.9798 97.6292 19.9798Z"
      fill="white"
    />
    <path
      d="M108.239 19.9798C110.015 19.9798 111.45 19.1333 112.211 17.6545C112.355 17.3743 112.241 17.039 111.977 16.8671L110.974 16.2151C110.632 15.9927 110.179 16.1322 109.93 16.4551C109.463 17.0613 108.873 17.3274 108.222 17.3274C106.849 17.3274 105.771 16.1876 105.771 14.2397C105.771 12.2918 106.849 11.1521 108.222 11.1521C108.874 11.1521 109.465 11.4312 109.932 12.0359C110.179 12.3554 110.627 12.4928 110.967 12.275L111.975 11.6298C112.24 11.4598 112.356 11.1256 112.215 10.8442C111.456 9.33248 110.019 8.49966 108.239 8.49966C105.197 8.49966 103.023 10.8827 103.023 14.2397C103.023 17.5968 105.197 19.9798 108.239 19.9798Z"
      fill="white"
    />
    <path
      d="M116.582 10.1367V9.33518C116.582 8.96529 116.281 8.66544 115.911 8.66544H114.663C114.292 8.66544 113.991 8.96529 113.991 9.33518V19.1443C113.991 19.5142 114.292 19.8141 114.663 19.8141H116.032C116.403 19.8141 116.704 19.5142 116.704 19.1443V14.5506C116.704 12.4162 117.695 11.4422 119.19 11.4422C119.518 11.4422 119.799 11.2017 119.799 10.8749V9.1694C119.799 8.79951 119.496 8.49358 119.129 8.54283C118.01 8.69284 117.137 9.23231 116.582 10.1367Z"
      fill="white"
    />
    <path
      d="M121.411 19.1443C121.411 19.5142 121.712 19.8141 122.083 19.8141H123.452C123.823 19.8141 124.124 19.5142 124.124 19.1443V9.33518C124.124 8.96529 123.823 8.66544 123.452 8.66544H122.083C121.712 8.66544 121.411 8.96529 121.411 9.33518V19.1443Z"
      fill="white"
    />
    <path
      d="M132.17 8.49966C130.918 8.49966 129.91 8.97627 129.232 9.95022V9.33518C129.232 8.96529 128.931 8.66544 128.56 8.66544H127.312C126.941 8.66544 126.641 8.96529 126.641 9.33518V23.1645C126.641 23.5343 126.941 23.8342 127.312 23.8342H128.682C129.053 23.8342 129.353 23.5343 129.353 23.1645V18.6329C130.049 19.5447 131.005 19.9798 132.17 19.9798C134.795 19.9798 136.83 17.7418 136.83 14.2397C136.83 10.7377 134.795 8.49966 132.17 8.49966ZM131.7 17.3274C130.344 17.3274 129.318 16.1669 129.318 14.2397C129.318 12.3126 130.344 11.1521 131.7 11.1521C133.057 11.1521 134.082 12.3126 134.082 14.2397C134.082 16.1669 133.057 17.3274 131.7 17.3274Z"
      fill="white"
    />
    <path
      d="M144.267 17.9913C144.118 17.5261 143.491 17.3593 143.003 17.4034C142.953 17.408 142.903 17.4103 142.853 17.4103C142.123 17.4103 141.688 16.8922 141.688 15.939V11.4008H143.346C143.717 11.4008 144.018 11.1009 144.018 10.731V9.58385C144.018 9.21396 143.717 8.9141 143.346 8.9141H141.688V6.86922C141.688 6.49933 141.387 6.19948 141.017 6.19948H139.647C139.276 6.19948 138.976 6.49933 138.976 6.86922V8.9141H138.204C137.833 8.9141 137.532 9.21396 137.532 9.58385V10.731C137.532 11.1009 137.833 11.4008 138.204 11.4008H138.976V15.9804C138.976 18.6329 140.262 19.9798 142.47 19.9798C143.138 19.9798 143.795 19.8339 144.316 19.5313C144.549 19.3959 144.625 19.1088 144.543 18.8525L144.267 17.9913Z"
      fill="white"
    />
    <path
      d="M146.201 19.1443C146.201 19.5142 146.502 19.8141 146.872 19.8141H148.242C148.613 19.8141 148.913 19.5142 148.913 19.1443V9.33518C148.913 8.96529 148.613 8.66544 148.242 8.66544H146.872C146.502 8.66544 146.201 8.96529 146.201 9.33518V19.1443Z"
      fill="white"
    />
    <path
      d="M154.404 8.43749C154.404 7.3392 154.838 6.82115 155.638 6.82115C155.695 6.82115 155.752 6.82386 155.807 6.82919C156.287 6.87514 156.89 6.7024 157.03 6.24209L157.316 5.30069C157.395 5.04187 157.313 4.75393 157.071 4.63087C156.589 4.3853 155.973 4.27229 155.377 4.27229C153.048 4.27229 151.761 5.90936 151.761 8.41677V8.9141H150.989C150.618 8.9141 150.318 9.21396 150.318 9.58385V10.731C150.318 11.1009 150.618 11.4008 150.989 11.4008H151.761V19.1443C151.761 19.5142 152.062 19.8141 152.432 19.8141H153.802C154.173 19.8141 154.473 19.5142 154.473 19.1443V11.4008H156.132C156.503 11.4008 156.803 11.1009 156.803 10.731V9.58385C156.803 9.21396 156.503 8.9141 156.132 8.9141H154.404V8.43749Z"
      fill="white"
    />
    <path
      d="M166.149 8.66544C165.863 8.66544 165.609 8.84569 165.515 9.11474L163.029 16.2291L160.56 9.11603C160.467 8.84632 160.212 8.66544 159.926 8.66544H158.56C158.095 8.66544 157.771 9.12551 157.928 9.56194L161.656 19.897L161.621 20.0006C161.256 20.9952 160.839 21.389 160.091 21.389C159.86 21.389 159.623 21.3395 159.394 21.2487C158.947 21.0713 158.35 21.1734 158.16 21.6142L157.744 22.5801C157.638 22.8256 157.685 23.116 157.899 23.2765C158.516 23.7386 159.377 24 160.178 24C161.864 24 163.151 23.1918 164.055 20.4979L167.961 9.55992C168.116 9.12384 167.792 8.66544 167.328 8.66544H166.149Z"
      fill="white"
    />
    <path
      d="M124.519 5.40626C124.519 6.42437 123.806 7.21109 122.789 7.21109C121.794 7.21109 121.059 6.44751 121.059 5.47568C121.059 4.50384 121.794 3.74026 122.789 3.74026C123.784 3.74026 124.519 4.45756 124.519 5.40626Z"
      fill="white"
    />
    <path
      d="M149.236 5.40623C149.236 6.42434 148.522 7.21106 147.506 7.21106C146.511 7.21106 145.776 6.44748 145.776 5.47565C145.776 4.50382 146.511 3.74023 147.506 3.74023C148.501 3.74023 149.236 4.45754 149.236 5.40623Z"
      fill="white"
    />
    <path
      d="M58.4643 19.8278C58.8357 19.8278 59.1366 19.5269 59.1357 19.1564L59.1041 6.03785C59.1032 5.66858 58.8029 5.36971 58.4328 5.36971H57.2063C56.9516 5.36971 56.7189 5.51348 56.6053 5.74091L52.3059 14.3544L47.9379 5.73722C47.8237 5.51182 47.592 5.36971 47.3388 5.36971H46.0937C45.7229 5.36971 45.4224 5.66956 45.4224 6.03945V19.158C45.4224 19.5279 45.7229 19.8278 46.0937 19.8278H47.3932C47.764 19.8278 48.0645 19.5279 48.0645 19.158V11.3388L51.4411 17.9342C51.5558 18.1583 51.7867 18.2993 52.0389 18.2993H52.4819C52.7363 18.2993 52.9687 18.156 53.0824 17.9291L56.4777 11.1529L56.4938 19.1594C56.4945 19.5287 56.7949 19.8278 57.1651 19.8278H58.4643Z"
      fill="white"
    />
    <path
      d="M1.26894 23.3765C0.568124 23.3765 5.39975e-08 22.8107 5.39975e-08 22.1129V1.2636C5.39975e-08 0.565735 0.568124 0 1.26894 0H5.8882C6.32703 0 6.73471 0.225777 6.96621 0.597007L16.1109 15.2615H12.644L21.519 0.610916C21.7487 0.231769 22.1609 0 22.6056 0H27.2177C27.9171 0 28.4846 0.563506 28.4867 1.25994L28.5473 22.1092C28.5493 22.8085 27.9806 23.3765 27.2784 23.3765H22.5054C21.8073 23.3765 21.2403 22.8149 21.2365 22.1197L21.1753 10.82H22.331L16.3789 20.6609C16.1493 21.0405 15.7369 21.2726 15.2919 21.2726H13.1755C12.739 21.2726 12.3331 21.0492 12.101 20.6811L5.88014 10.82H7.30769V22.1129C7.30769 22.8107 6.73957 23.3765 6.03875 23.3765H1.26894Z"
      fill="#63FFF6"
    />
    <path
      d="M7.90593 5.15181C8.11442 4.70692 8.56281 4.42256 9.05582 4.42256H35.4407C36.369 4.42256 36.9831 5.38278 36.5905 6.22052L28.8924 22.6472C28.6839 23.0921 28.2355 23.3765 27.7425 23.3765H1.35764C0.316616 23.3766 -0.000151007 22.8144 5.39975e-08 22.1129C0.01323 21.9734 0.143252 21.7161 0.207746 21.5785L7.90593 5.15181Z"
      fill="url(#paint0_linear_0_1)"
    />
    <path
      d="M6.02826 23.3766H22.5229C21.8276 23.3733 21.2639 22.813 21.2601 22.1199L21.209 12.7139L16.3877 20.6625C16.1579 21.0413 15.7459 21.2728 15.3015 21.2728H13.1727C12.7368 21.2728 12.3314 21.05 12.0991 20.6827L7.29107 13.0831V22.113C7.29107 22.8089 6.72626 23.3733 6.02826 23.3766Z"
      fill="url(#paint1_linear_0_1)"
    />
    <path
      d="M19.21 4.42256H9.35181L14.3561 12.4416L19.21 4.42256Z"
      fill="url(#paint2_linear_0_1)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_0_1"
        x1="18.3556"
        y1="-4.28571"
        x2="18.3556"
        y2="23.3766"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.182292" stopColor="#00FFEF" />
        <stop offset="0.942708" stopColor="#292856" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_0_1"
        x1="14.2754"
        y1="4.42276"
        x2="14.5902"
        y2="24.6403"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.348958" stopColor="#39E8DD" />
        <stop offset="0.953125" stopColor="#292856" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_0_1"
        x1="14.2754"
        y1="4.42276"
        x2="14.5902"
        y2="24.6403"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.348958" stopColor="#39E8DD" />
        <stop offset="0.953125" stopColor="#292856" />
      </linearGradient>
    </defs>
  </svg>
);
