import { useState } from 'react';
import { Exit } from './icons/Exit';
import { InformationCircle } from './icons/InformationCircle';

interface MessagePanelProps {
  message: React.ReactNode;
  showExit?: boolean;
}

export const MessagePanel = ({
  message,
  showExit = true,
}: MessagePanelProps) => {
  const [showPanel, setShowPanel] = useState(true);
  if (!showPanel) {
    return null;
  }
  return (
    <section className="m-5 mb-3 flex rounded-lg border border-violet-3 py-4">
      <span className="mx-[14px] mt-[1px]">
        <InformationCircle />
      </span>
      <div className="mr-4 flex grow">
        <p className="grow">{message}</p>
        {showExit && (
          <button type="button" onClick={() => setShowPanel(false)}>
            {Exit}
          </button>
        )}
      </div>
    </section>
  );
};
