import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PROJECTS } from '../../consts/queryKeys';
import { ROUTES } from '../../consts/routes';
import { Project } from '../../interfaces/projects';
import { getProjects } from '../../utils/api/getProjects';
import { removeProject } from '../../utils/api/removeProject';
import { queryClient } from '../../utils/queryClients';
import { FolderOpen } from '../icons/FolderOpen';
import { Spinner } from '../icons/Spinner';
import { ProjectCardDropdown } from '../Dropdown';
import { getIsTokenExpired } from '../../utils/getIsTokenExpired';
import { ErrorPanel } from '../ErrorPanel';
import { MessagePanel } from '../MessagePanel';
import { NewFilesLabel } from '../NewFilesLabel';
import { Tooltip } from '../Tooltip';
import { Login } from '../Login'

export const Detail = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [PROJECTS],
    queryFn: getProjects,
  });

  const { mutate: deleteProject, isError: deleteProjectError } = useMutation({
    mutationFn: removeProject,
    onError: () => {
      queryClient.invalidateQueries();
    },
  });

  if (isLoading) {
    return (
      <div className="mt-12 flex justify-center">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    if (getIsTokenExpired()) {
      window.location.replace(ROUTES.ROOT);
    }
    return <ErrorPanel error='Error unrelated to token expiry.' />
  }

  if (data?.length === 0) {
    return (
      <MessagePanel message="You have no projects to display. You can add them in the Browse panel." />
    );
  }

  const handleDeleteProject = (projectId: string) => {
    const oldProjects = queryClient.getQueryData([PROJECTS]) as Project[];
    const newProjects = oldProjects.filter(
      (project) => project.projectId !== projectId
    );
    queryClient.setQueryData([PROJECTS], newProjects);
    deleteProject(projectId);
  };

  return (
    <div className="px-5">
      {deleteProjectError && (
        <ErrorPanel error="Sorry, we were unable to delete this project." />
      )}
      {data?.map((project) => (
        <ProjectCard
          key={project.projectId}
          project={project}
          deleteProject={handleDeleteProject}
        />
      ))}
    </div>
  );
};

interface ProjectCardProps {
  project: Project;
  deleteProject: (projectId: string) => void;
}

const ProjectCard = ({ project, deleteProject }: ProjectCardProps) => {
  const { title, filePath = 'PLACEHOLDER PATH', newFilesCount } = project;

  const [isFilePathShowed, setIsFilePathShowed] = useState(false);
  const navigate = useNavigate();

  const croppedFilePath =
    filePath.length > 65
      ? `...${filePath.slice(filePath.length - 65, filePath.length)}`
      : filePath;

  return (
    <div
      className="mt-3 flex cursor-pointer items-start rounded-lg border border-violet-1 bg-darkblue-2 py-3 px-4 hover:border-violet-3"
      onClick={() =>
        navigate(ROUTES.PROJECT, { state: `${project.projectId}~${title}` })
      }
    >
      <div className="mt-[3px]">{FolderOpen}</div>
      <div className="ml-3 flex grow flex-col">
        <div className="flex grow items-center justify-between">
          <span className="medium-14">{title}</span>
          <div className="flex items-center">
            <NewFilesLabel newFilesCount={newFilesCount} />
            <ProjectCardDropdown
              projectId={project.projectId}
              showFilePath={() => setIsFilePathShowed(true)}
              hideFilePath={() => setIsFilePathShowed(false)}
              isPathShowed={isFilePathShowed}
              deleteProject={deleteProject}
            />
          </div>
        </div>
        {isFilePathShowed && (
          <Tooltip tooltipText={filePath}>
            <span className="medium-10 text-lightgray-1">
              {croppedFilePath}
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
