import { MY_PROJECTS_TIP_KEY } from '../../consts/localStorage';
import { ROUTES } from '../../consts/routes';
import { Elipsis } from '../icons/Ellipsis';
import { Navbar } from '../Navbar';
import { TipsPanel } from '../TipsPanel';
import { Detail } from './Detail';

export const Projects = () => {
  return (
    <>
      <Navbar
        title="My projects"
        redirectTo={ROUTES.BROWSE}
        quicklinkLabel="Browse"
      />
      <TipsPanel tipContent={Tip} id={MY_PROJECTS_TIP_KEY} />
      <Detail />
    </>
  );
};

const Tip = (
  <p>
    <span>
      This is where you'll manage your projects. Click the
      <span className="-mt-[3px] inline-block px-[6px] align-middle">
        <Elipsis size="small" />
      </span>
      icon to update metadata or remove them. Removing a project
      doesn’t delete any files. It just removes it from this list.
      You can always re-add the folder to My Projects in Browse mode.
    </span>
  </p>
);
