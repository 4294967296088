import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';
import { Loading } from './icons/Loading';

type ButtonProps = {
  children?: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'primary' | 'secondary';
  icon?: JSX.Element;
  iconPosition?: 'left' | 'right';
  buttonType?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
};

export const Button = ({
  children,
  onClick,
  disabled,
  icon,
  iconPosition,
  type = 'primary',
  buttonType = 'button',
  isLoading,
}: ButtonProps): ReactElement => {
  return (
    <button
      className={classNames(
        'flex w-full items-center justify-center rounded-full text-sm font-semibold uppercase leading-5  disabled:cursor-not-allowed',
        {
          'border py-2 px-5 disabled:border-gray disabled:text-gray dark:border-white dark:bg-darkblue-1 dark:text-white dark:hover:border-primary dark:hover:bg-primary/10 dark:active:border-primary-pressed dark:active:bg-primary/20':
            type === 'secondary',
          'py-3 px-5 dark:bg-primary dark:text-darkblue-1 dark:hover:bg-primary-hover dark:active:bg-primary-pressed dark:disabled:bg-gray':
            type === 'primary',
        }
      )}
      onClick={onClick}
      type={buttonType}
      disabled={disabled || isLoading}
    >
      {iconPosition === 'left' && <span className="mr-[10px]">{icon}</span>}
      {isLoading ? (
        <span className="animate-spin">
          <Loading />
        </span>
      ) : (
        children
      )}
      {iconPosition === 'right' && <span className="ml-[10px]">{icon}</span>}
    </button>
  );
};
