import classNames from 'classnames';

interface QuicklinkProps {
  label: string;
  onClick: () => void;
  icon?: (color: string) => JSX.Element;
  disabled?: boolean;
  iconPosition?: 'left' | 'right';
  size?: 'medium' | 'small';
}

export const Quicklink = ({
  disabled,
  icon,
  iconPosition = 'right',
  size = 'medium',
  label,
  onClick,
}: QuicklinkProps) => {
  const strokeColor = disabled ? '#8D92CC' : '#5856A7';
  return (
    <button
      type="button"
      className={classNames('align-center flex mb-4 text-left hover:underline', {
        'cursor-not-allowed hover:no-underline dark:text-gray dark:hover:text-gray dark:active:text-gray':
          disabled,
        'dark:hover:text-primary-hover dark:active:text-primary-pressed':
          !disabled,
      })}
      onClick={disabled ? undefined : onClick}
    >
      {icon && iconPosition === 'left' && (
        <span className="mr-2">{icon(strokeColor)}</span>
      )}
      <span
        className={classNames({
          'medium-14': size === 'medium',
          'medium-12': size === 'small',
        })}
      >
        {label}
      </span>
      {icon && iconPosition === 'right' && (
        <span className="ml-2 mt-[0.4rem]">{icon(strokeColor)}</span>
      )}
    </button>
  );
};
