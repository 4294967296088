export const DragCursor = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 5L13 7L11 9M9 7H13M3 5L1 7L3 9M1 7H5M5 11L7 13L9 11M7 9V13M9 3L7 1L5 3M7 1V5"
      stroke="#A0FFF9"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
