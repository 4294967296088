import { ENDPOINTS } from '../../consts/endpoints';
import { URLS } from '../../consts/urls';
import { ProjectsResponse } from '../../interfaces/api';
import { projectResponseAdapter } from '../adapters';
import { authorizatedFetcher } from './authorizatedFetcher';

export const getProjects = async () => {
  const response = await authorizatedFetcher(
    `${URLS.MANUSCRIPTIFY_API_URL}${ENDPOINTS.PROJECTS}`
  );
  const data: ProjectsResponse = await response.json();
  return projectResponseAdapter(data);
};
