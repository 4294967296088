export const Spinner = ({ size = 66 }) => (
  <span className="animate-spin">
    <svg
      width={`${size}`}
      height={`${size}`}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63.8005 35.1995H55.3851C54.1706 35.1995 53.1856 34.2145 53.1856 33C53.1856 31.7855 54.1706 30.8005 55.3851 30.8005H63.8005C65.015 30.8005 66 31.7855 66 33C66 34.2152 65.0157 35.1995 63.8005 35.1995Z"
        fill="url(#paint0_linear_5_8241)"
      />
      <path
        d="M60.7705 19.4982L53.4838 23.7071C52.4321 24.3151 51.0865 23.9545 50.4793 22.9027C49.8714 21.851 50.2319 20.5055 51.2837 19.8982L58.5703 15.6892C59.6221 15.0813 60.9676 15.4419 61.5749 16.4936C62.1828 17.5454 61.8229 18.8909 60.7705 19.4982Z"
        fill="url(#paint1_linear_5_8241)"
      />
      <path
        d="M50.2936 7.41762L46.0891 14.7074C45.4825 15.7598 44.1369 16.121 43.0845 15.5137C42.0321 14.9071 41.671 13.5616 42.2782 12.5092L46.4827 5.21936C47.0894 4.16697 48.4349 3.80579 49.4873 4.41306C50.539 5.02033 50.9002 6.36587 50.2936 7.41762Z"
        fill="url(#paint2_linear_5_8241)"
      />
      <path
        d="M35.1786 2.19826L35.1843 10.6136C35.1849 11.8281 34.2012 12.8138 32.986 12.8144C31.7715 12.815 30.7858 11.8313 30.7852 10.6161L30.7795 2.20081C30.7782 0.986261 31.7626 0.000636195 32.9771 3.0811e-07C34.1923 -0.000635579 35.1773 0.983082 35.1786 2.19826Z"
        fill="url(#paint3_linear_5_8241)"
      />
      <path
        d="M19.4791 5.23844L23.6932 12.5225C24.3017 13.5743 23.9424 14.9198 22.8907 15.5277C21.8389 16.1363 20.4934 15.777 19.8855 14.7252L15.6714 7.44115C15.0629 6.38939 15.4222 5.04386 16.4739 4.43595C17.5251 3.82804 18.8706 4.18732 19.4791 5.23844Z"
        fill="url(#paint4_linear_5_8241)"
      />
      <path
        d="M7.40618 15.7242L14.6985 19.9236C15.7516 20.5296 16.1134 21.8745 15.5074 22.9275C14.9014 23.9806 13.5565 24.3424 12.5035 23.7364L5.21046 19.5364C4.15807 18.9304 3.79625 17.5855 4.40225 16.5331C5.00825 15.48 6.35315 15.1182 7.40618 15.7242Z"
        fill="url(#paint5_linear_5_8241)"
      />
      <path
        d="M2.19636 30.8431L10.6117 30.8316C11.8262 30.8297 12.8125 31.8134 12.8144 33.028C12.8163 34.2425 11.8326 35.2288 10.618 35.2307L2.20272 35.2421C0.987535 35.244 0.00191043 34.2603 2.76987e-06 33.0458C-0.00190489 31.8306 0.981812 30.8443 2.19636 30.8431Z"
        fill="url(#paint6_linear_5_8241)"
      />
      <path
        d="M5.24798 46.5399L12.5289 42.3208C13.58 41.7116 14.9255 42.0703 15.5347 43.1214C16.1439 44.1725 15.7853 45.5181 14.7341 46.1273L7.45324 50.3464C6.40211 50.9555 5.05658 50.5969 4.4474 49.5458C3.83885 48.4947 4.19686 47.1491 5.24798 46.5399Z"
        fill="url(#paint7_linear_5_8241)"
      />
      <path
        d="M15.742 58.6059L19.9363 51.3104C20.5417 50.2573 21.8866 49.8942 22.9396 50.4996C23.9927 51.105 24.3558 52.4499 23.7504 53.5029L19.5561 60.7984C18.9507 61.8515 17.6058 62.2146 16.5528 61.6092C15.4991 61.0038 15.1367 59.6589 15.742 58.6059Z"
        fill="url(#paint8_linear_5_8241)"
      />
      <path
        d="M30.8641 63.8049L30.8469 55.3896C30.8443 54.175 31.8274 53.1881 33.042 53.1856C34.2565 53.1831 35.2434 54.1661 35.246 55.3807L35.2631 63.796C35.2657 65.0106 34.2826 65.9975 33.068 66C31.8535 66.0025 30.8666 65.0201 30.8641 63.8049Z"
        fill="url(#paint9_linear_5_8241)"
      />
      <path
        d="M46.559 60.7431L42.3348 53.4654C41.725 52.4149 42.0824 51.0687 43.1329 50.4589C44.1833 49.8491 45.5295 50.2065 46.1393 51.257L50.3635 58.5347C50.9734 59.5852 50.616 60.9313 49.5655 61.5412C48.515 62.151 47.1688 61.7936 46.559 60.7431Z"
        fill="url(#paint10_linear_5_8241)"
      />
      <path
        d="M58.618 50.2408L51.3199 46.0516C50.2662 45.4469 49.9025 44.1026 50.5072 43.0489C51.112 41.9953 52.4562 41.6315 53.5099 42.2363L60.808 46.4255C61.8616 47.0302 62.2254 48.3745 61.6206 49.4281C61.0159 50.4812 59.6716 50.8455 58.618 50.2408Z"
        fill="url(#paint11_linear_5_8241)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5_8241"
          x1="33"
          y1="0"
          x2="4.62"
          y2="17.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0FFF9" stopOpacity="0.46" />
          <stop offset="1" stopColor="#A0FFF9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5_8241"
          x1="33"
          y1="0"
          x2="4.62"
          y2="17.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0FFF9" stopOpacity="0.46" />
          <stop offset="1" stopColor="#A0FFF9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5_8241"
          x1="33"
          y1="0"
          x2="4.62"
          y2="17.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0FFF9" stopOpacity="0.46" />
          <stop offset="1" stopColor="#A0FFF9" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5_8241"
          x1="33"
          y1="0"
          x2="4.62"
          y2="17.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0FFF9" stopOpacity="0.46" />
          <stop offset="1" stopColor="#A0FFF9" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5_8241"
          x1="33"
          y1="0"
          x2="4.62"
          y2="17.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0FFF9" stopOpacity="0.46" />
          <stop offset="1" stopColor="#A0FFF9" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5_8241"
          x1="33"
          y1="0"
          x2="4.62"
          y2="17.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0FFF9" stopOpacity="0.46" />
          <stop offset="1" stopColor="#A0FFF9" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5_8241"
          x1="33"
          y1="0"
          x2="4.62"
          y2="17.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0FFF9" stopOpacity="0.46" />
          <stop offset="1" stopColor="#A0FFF9" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5_8241"
          x1="33"
          y1="0"
          x2="4.62"
          y2="17.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0FFF9" stopOpacity="0.46" />
          <stop offset="1" stopColor="#A0FFF9" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_5_8241"
          x1="33"
          y1="0"
          x2="4.62"
          y2="17.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0FFF9" stopOpacity="0.46" />
          <stop offset="1" stopColor="#A0FFF9" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_5_8241"
          x1="33"
          y1="0"
          x2="4.62"
          y2="17.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0FFF9" stopOpacity="0.46" />
          <stop offset="1" stopColor="#A0FFF9" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_5_8241"
          x1="33"
          y1="0"
          x2="4.62"
          y2="17.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0FFF9" stopOpacity="0.46" />
          <stop offset="1" stopColor="#A0FFF9" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_5_8241"
          x1="33"
          y1="0"
          x2="4.62"
          y2="17.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0FFF9" stopOpacity="0.46" />
          <stop offset="1" stopColor="#A0FFF9" />
        </linearGradient>
      </defs>
    </svg>
  </span>
);
