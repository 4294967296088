export const ENDPOINTS = {
  GOOGLE_AUTH: '/g1',
  GOOGLE_AUTH_GRANTED: '/g2',
  VALIDATE_SESSION: '/g5',
  NODE_TREE: '/n',
  PROJECTS: '/p',
  PROJECTS_SETTINGS: '/ps',
  SUBSCRIPTION_STATUS: '/s',
  DROP_AFTER_DRAGGING: '/d',
  GENERATE_MANUSCRIPT: '/m',
  ADD_PROJECT: '/pa',
  REMOVE_PROJECT: '/pr',
  UPDATE_PROJECT: '/pu',
  REFRESH_TOKEN: '/tr',
  USER_PROFILE: '/u',
  UPDATE_USER_PROFILE: '/us',
  THANK_YOU: '/t',
} as const;
