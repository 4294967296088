interface HeaderProps {
  leftIcon: JSX.Element;
  onLeftIconClick?: () => void;
}

export const Header = ({
  onLeftIconClick,
  leftIcon: LeftIcon,
}: HeaderProps) => {
  return (
    <header className="flex items-center justify-between border-b border-violet-1 py-4 pl-5">
      {onLeftIconClick ? (
        <button
          title="manuscriptify logo"
          type="button"
          onClick={onLeftIconClick}
        >
          {LeftIcon}
        </button>
      ) : (
        LeftIcon
      )}
    </header>
  );
};
