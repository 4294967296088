import classNames from 'classnames';
import { ReactElement } from 'react';
import { Checked } from './icons/Checked';

type ButtonProps = {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
};

export const Checkbox = ({
  onChange,
  checked,
  disabled,
}: ButtonProps): ReactElement => {
  const strokeColor = disabled ? '#8D92AA' : '#A0FFF9';
  const handleClick = () => (
    disabled ? undefined : onChange()
  );
  return (
    <span
      className={classNames('flex items-center align-middle', {
        'mr-1': checked,
      })}
    >
      <input
        className={classNames(
          'h-[15px] w-[15px] cursor-pointer appearance-none rounded-sm border disabled:cursor-not-allowed dark:border-violet-3 dark:bg-darkblue-1 dark:checked:border-primary dark:hover:border-primary-hover dark:active:border-primary-pressed dark:disabled:border-gray'
        )}
        onChange={handleClick}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        title="Select this project"
      />
      {checked && (
        <label
          className={classNames('-ml-3 cursor-pointer', {
            'cursor-not-allowed': disabled,
          })}
          onClick={handleClick}
        >
          {Checked(strokeColor)}
        </label>
      )}
    </span>
  );
};
