interface InformationCircleProps {
  color?: string;
}

export const InformationCircle = ({
  color = '#A0FFF9',
}: InformationCircleProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.1875C3.69219 0.1875 0.1875 3.69219 0.1875 8C0.1875 12.3078 3.69219 15.8125 8 15.8125C12.3078 15.8125 15.8125 12.3078 15.8125 8C15.8125 3.69219 12.3078 0.1875 8 0.1875ZM8 3.39062C8.20087 3.39062 8.39723 3.45019 8.56425 3.56179C8.73127 3.67339 8.86145 3.83201 8.93832 4.01759C9.01519 4.20317 9.0353 4.40738 8.99611 4.60439C8.95692 4.8014 8.86019 4.98237 8.71815 5.12441C8.57612 5.26644 8.39515 5.36317 8.19814 5.40236C8.00113 5.44155 7.79692 5.42144 7.61134 5.34457C7.42576 5.26769 7.26714 5.13752 7.15554 4.9705C7.04394 4.80348 6.98438 4.60712 6.98438 4.40625C6.98438 4.13689 7.09138 3.87856 7.28184 3.68809C7.47231 3.49763 7.73064 3.39062 8 3.39062ZM9.875 12.2188H6.4375C6.27174 12.2188 6.11277 12.1529 5.99556 12.0357C5.87835 11.9185 5.8125 11.7595 5.8125 11.5938C5.8125 11.428 5.87835 11.269 5.99556 11.1518C6.11277 11.0346 6.27174 10.9688 6.4375 10.9688H7.53125V7.53125H6.90625C6.74049 7.53125 6.58152 7.4654 6.46431 7.34819C6.3471 7.23098 6.28125 7.07201 6.28125 6.90625C6.28125 6.74049 6.3471 6.58152 6.46431 6.46431C6.58152 6.3471 6.74049 6.28125 6.90625 6.28125H8.15625C8.32201 6.28125 8.48098 6.3471 8.59819 6.46431C8.7154 6.58152 8.78125 6.74049 8.78125 6.90625V10.9688H9.875C10.0408 10.9688 10.1997 11.0346 10.3169 11.1518C10.4342 11.269 10.5 11.428 10.5 11.5938C10.5 11.7595 10.4342 11.9185 10.3169 12.0357C10.1997 12.1529 10.0408 12.2188 9.875 12.2188Z"
      fill={color}
    />
  </svg>
);
