import { ENDPOINTS } from '../../consts/endpoints';
import { URLS } from '../../consts/urls';
import { MoveFileData } from '../../interfaces/folderTree';
import { moveFileDataAdapter } from '../adapters';
import { authorizatedFetcher } from './authorizatedFetcher';

export const moveFile = async (data: MoveFileData) => {
  return await authorizatedFetcher(
    `${URLS.MANUSCRIPTIFY_API_URL}${ENDPOINTS.DROP_AFTER_DRAGGING}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(moveFileDataAdapter(data)),
    }
  );
};
