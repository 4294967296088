import { ENDPOINTS } from '../../consts/endpoints';
import { URLS } from '../../consts/urls';
import { ProjectSettingsResponse } from '../../interfaces/api';
import { projectSettingsResponseAdapter } from '../adapters';
import { authorizatedFetcher } from './authorizatedFetcher';

export const getProjectSettings = async (projectId: string | undefined) => {
  if (!projectId) throw new Error('Project id is not defined');

  const url = new URL(
    `${URLS.MANUSCRIPTIFY_API_URL}${ENDPOINTS.PROJECTS_SETTINGS}`
  );
  url.searchParams.append('project_id', projectId);

  const response = await authorizatedFetcher(url.toString());
  const data: ProjectSettingsResponse = await response.json();
  return projectSettingsResponseAdapter(data);
};
