import { TreeItems } from 'dnd-kit-sortable-tree';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../consts/routes';
import { MinimalTreeItemData } from '../../interfaces/folderTree';
import { findAllCheckedFoldersIds } from '../../utils/findAllCheckedIds';
import { checkIfAnyFolderChildChecked } from '../../utils/folderTree';
import { Button } from '../Button';

interface FooterProps {
  items?: TreeItems<MinimalTreeItemData>;
}

export const Footer = ({ items }: FooterProps) => {
  const navigate = useNavigate();
  const handleAddToMyProjects = () => {
    if (!items || items.length === 0) return;
    const selectedFolders = findAllCheckedFoldersIds(items);
    navigate(ROUTES.ADD_PROJECT, { state: { selectedFolders } });
  };

  const isButtonDisabled = Boolean(
    !items || !checkIfAnyFolderChildChecked(items)
  );

  return (
    <footer className="flex flex-col mt-auto w-full border-t-2 border-primary p-4">
      <div className="flex items-center justify-center border-violet-1">
        <span>
          <Button onClick={handleAddToMyProjects} disabled={isButtonDisabled}>
            Add to my projects
          </Button>
        </span>
      </div>
    </footer>
  );
};
