import { ChangeEvent } from 'react';

interface InputProps {
  label: string;
  value: string;
  name: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  error?: string | null;
}

export const Input = ({ label, onChange, value, error, name }: InputProps) => {
  return (
    <div>
      <label className="flex flex-col">
        <span>{label}</span>
        <input
          name={name}
          className="mt-1 rounded-lg border border-violet-2 p-2 dark:bg-darkblue-1"
          type="text"
          value={value}
          onChange={(e) => onChange(e)}
        />
      </label>
      <div className="h-5">
        <label className="text-sm text-red">{Boolean(error) && error}</label>
      </div>
    </div>
  );
};
