import { Footer } from './Footer';
import { useMutation } from '@tanstack/react-query';
import { FolderTree } from './FolderTree';
import { getFolder } from '../../utils/api/getFolder';
import { getIsTokenExpired } from '../../utils/getIsTokenExpired';
import { ROUTES } from '../../consts/routes';
import { Navbar } from '../Navbar';
import { FolderTreeNode } from '../../interfaces/projects';
import { Spinner } from '../icons/Spinner';
import { ErrorPanel } from '../ErrorPanel';
import { MessagePanel } from '../MessagePanel';
import { Loading } from '../icons/Loading';
import { useEffect } from 'react';

export const Browse = () => {
  const { data, isPending, isError, mutate } = useMutation({
    mutationFn: getFolder,
  });

  useEffect(() => {
    mutate('root');
  }, []);

  if (!data) {
    return (
      <LoadingView />
    );
  }

  if (isPending) {
    return (
      <LoadingView />
    );
  }

  if (isError) {
    if (getIsTokenExpired()) {
      window.location.replace(ROUTES.ROOT);
    }
    return <ErrorPanel error='Error unrelated to token expiry.' />
  }

  return (
    <BrowsePageView
      data={data}
    />
  );
};

const LoadingView = () => (
  <>
    <Navbar
      title="Browse"
      redirectTo={ROUTES.PROJECTS}
      quicklinkLabel="My projects"
    />
    <div className="m-5 flex justify-between">
      <h2>Select the folder(s) you wish to add...</h2>
    </div>
    <div className="mt-12 flex justify-center">
      <Spinner />
    </div>
    <Footer />
  </>
);

interface BrowsePageViewProps {
  data: FolderTreeNode;
}

const BrowsePageView = ({
  data,
}: BrowsePageViewProps) => {
  return (
    <>
      <Navbar
        title="Browse"
        redirectTo={ROUTES.PROJECTS}
        quicklinkLabel="My projects"
      />
      <div className="m-5 flex justify-between">
        <h2>Select the folder(s) you wish to add...</h2>
      </div>
      {data.children?.length === 0 ? (
        <MessagePanel
          message="To add a folder as a project, you must own the folder. The current location doesn't seem to contain any folders that are owned by you. Try navigating to where your writing is, and refreshing this panel."
          showExit={false}
        />
      ) : (
        <FolderTree data={data} />
      )}
    </>
  );
};
