import {
  SimpleTreeItemWrapper,
  TreeItem,
  TreeItemComponentProps,
  TreeItems,
} from 'dnd-kit-sortable-tree';
import React, { useEffect, useState } from 'react';
import { CaretDown } from '../components/icons/CaretDown';
import { CaretRight } from '../components/icons/CaretRight';
import { DragCursor } from '../components/icons/DragCursor';
import { FolderOpen } from '../components/icons/FolderOpen';
import { GoogleDocument } from '../components/icons/GoogleDocument';
import { Checkbox } from '../components/Checkbox';
import { Tooltip } from '../components/Tooltip';
import { MinimalTreeItemData } from '../interfaces/folderTree';
import { FolderTreeNode } from '../interfaces/projects';

export const checkAllChildrens = (
  childrens: TreeItems<MinimalTreeItemData> | undefined,
  isChecked: boolean
): TreeItems<MinimalTreeItemData> | undefined => {
  if (!childrens) {
    return undefined;
  }
  return childrens.map((child) => {
    return {
      ...child,
      isCheck: isChecked,
      children: child.children
        ? checkAllChildrens(child.children, isChecked)
        : undefined,
    };
  });
};

const getParentByChildId = (
  id: string,
  items: TreeItems<MinimalTreeItemData>
): TreeItem<MinimalTreeItemData> | undefined => {
  for (const item of items) {
    if (item.children) {
      const childIds = item.children.map((child) => child.folderId);
      if (childIds.includes(id)) {
        return item;
      } else {
        const parentItem = getParentByChildId(id, item.children);
        if (parentItem) {
          return parentItem;
        }
      }
    }
  }
  return undefined;
};

const updateItem = (
  id: string,
  items: TreeItems<MinimalTreeItemData>,
  value?: boolean
): TreeItems<MinimalTreeItemData> => {
  return items.map((item) => {
    if (item.folderId === id) {
      const newValue = value !== undefined ? value : !item.isCheck;
      const newChildrens = checkAllChildrens(item.children, newValue);
      return {
        ...item,
        isCheck: newValue,
        children: newChildrens,
      };
    }
    if (item.children) {
      return {
        ...item,
        children: updateItem(id, item.children, value),
      };
    }
    return item;
  });
};

const updateParents = (
  id: string,
  items: TreeItems<MinimalTreeItemData>
): void => {
  const parent = getParentByChildId(id, items);
  if (parent) {
    parent.isCheck = checkIfAllChildrenChecked(parent.children || []);
    updateParents(parent.folderId, items);
  }
};

export const checkItem = (
  id: string,
  items: TreeItems<MinimalTreeItemData>,
  value?: boolean
): TreeItems<MinimalTreeItemData> => {
  const newItems = updateItem(id, items, value);
  updateParents(id, newItems);
  return newItems;
};

export const checkIfAllChildrenChecked = (
  childrens: TreeItems<MinimalTreeItemData>
) => {
  return childrens.every((child) => child.isCheck);
};

export const convertFolderTreeNodesToTreeItems = (
  folderTreeNodes: FolderTreeNode[]
): TreeItems<MinimalTreeItemData> => {
  return folderTreeNodes.map((folderTreeNode) => ({
    id: folderTreeNode.id,
    folderId: folderTreeNode.id,
    name: folderTreeNode.name,
    type: folderTreeNode.type,
    new: folderTreeNode.new,
    children: convertFolderTreeNodesToTreeItems(folderTreeNode.children || []),
    canHaveChildren: folderTreeNode.type === 'folder',
    collapsed: true,
    disableSorting: true,
    isCheck: false,
  }));
};

export const checkIfAnyTreeItemChildChecked = (
  children: TreeItems<MinimalTreeItemData>
) => {
  const checked = children.some((child) => child.isCheck);
  if (checked) {
    return true;
  }
  return children.some((child) => {
    if (child.children) {
      const checked = checkIfAnyTreeItemChildChecked(child.children);
      if (checked) {
        return true;
      }
    }
    return false;
  });
};

export const checkIfAnyFolderChildChecked = (
  items: TreeItems<MinimalTreeItemData>
) => {
  const checked = items.some((child) => child.isCheck);
  if (checked) {
    return true;
  }
  return items.some((child) => {
    if (child.children) {
      const checked = checkIfAnyFolderChildChecked(child.children);
      if (checked) {
        return true;
      }
    }
    return false;
  });
};

export const getCheckedIds = (
  items: TreeItems<MinimalTreeItemData>
): string[] => {
  return items.reduce((acc: string[], item: TreeItem<MinimalTreeItemData>) => {
    if (item.isCheck) {
      acc.push(item.folderId);
    }

    if (item.children && item.children.length > 0) {
      acc.push(...getCheckedIds(item.children));
    }

    return acc;
  }, []);
};

export const checkItemWithoutFamily = (
  id: string,
  items: TreeItems<MinimalTreeItemData>,
  value?: boolean
): TreeItems<MinimalTreeItemData> => {
  return items.map((item) => {
    if (item.folderId === id) {
      const newValue = value !== undefined ? value : !item.isCheck;
      return {
        ...item,
        isCheck: newValue,
      };
    }
    if (item.children) {
      return {
        ...item,
        children: checkItemWithoutFamily(id, item.children, value),
      };
    }
    return item;
  });
};

export const getTreeItem = (
  handleCheckItem: (id: string) => void,
  canDrag: boolean = true
) =>
  React.forwardRef<HTMLDivElement, TreeItemComponentProps<MinimalTreeItemData>>(
    (props, ref) => {
      const [isAnimationEnabled, setIsAnimationEnabled] = useState(true);
      useEffect(() => {
        if (!isAnimationEnabled) {
          setTimeout(() => {
            if (props.onCollapse) props.onCollapse();
            setIsAnimationEnabled(true);
          }, 0);
        }
      }, [isAnimationEnabled]);

      return (
        <SimpleTreeItemWrapper
          {...props}
          ref={ref}
          hideCollapseButton={true}
          showDragHandle={false}
          onCollapse={() => {
            setIsAnimationEnabled(false);
          }}
          style={isAnimationEnabled ? props.style : undefined}
        >
          <div className="group/item mx-4 my-1 flex w-full items-center py-[6px] text-darkblue-1 hover:rounded hover:bg-violet-1 dark:text-white">
            <div className="ml-2 h-5 w-5">
              {Boolean(props.childCount && props.childCount > 0) && (
                <button
                  className="flex h-full w-full cursor-pointer items-center justify-center"
                  type="button"
                >
                  {props.collapsed ? CaretRight : CaretDown}
                </button>
              )}
            </div>
            <span className="ml-4" onClick={(e) => e.stopPropagation()}>
              <Checkbox
                checked={props.item.isCheck}
                onChange={() => handleCheckItem(props.item.folderId)}
              />
            </span>
            <div className="flex grow items-center">
              <span className="ml-4">
                {props.item.type === 'folder' &&
                  (props.collapsed ? FolderOpen : FolderOpen)}
                {props.item.type === 'file' && GoogleDocument}
              </span>
              <div className="flex grow items-center justify-between">
                <span className="medium-14 ml-3 w-[190px] md:w-full text-nowrap overflow-hidden">{props.item.name}</span>
                {canDrag && (
                  <div className="mr-2 flex items-center">
                    <div className="group/edit mr-2 hidden group-hover/item:block">
                      {DragCursor}
                    </div>
                    {props.item.new && (
                      <Tooltip tooltipText="New">
                        <span className="inline-block h-2 w-2 rounded-full bg-primary" />
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </SimpleTreeItemWrapper>
      );
    }
  );
