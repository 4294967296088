interface TooltipProps {
  children: React.ReactNode;
  tooltipText: React.ReactNode;
}

export const Tooltip = ({ children, tooltipText }: TooltipProps) => {
  return (
    <span className="tooltip">
      {children}
      <span className="tooltiptext">{tooltipText}</span>
    </span>
  );
};
