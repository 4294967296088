import { useState } from 'react';
import { Exit } from './icons/Exit';
import { InformationCircle } from './icons/InformationCircle';

interface ErrorPanelProps {
  error: React.ReactNode;
}

export const ErrorPanel = ({ error }: ErrorPanelProps) => {
  const [showPanel, setShowPanel] = useState(true);
  if (!showPanel) {
    return null;
  }
  return (
    <section className="m-5 mb-3 flex rounded-lg border border-violet-3 py-4">
      <span className="mx-[14px] mt-[1px]">
        <InformationCircle color="#FF5C5C" />
      </span>
      <div className="mr-4">
        <header className="mb-2 flex justify-between">
          <span className="medium-14 text-red">Error:</span>
          <button type="button" onClick={() => setShowPanel(false)}>
            {Exit}
          </button>
        </header>
        <p>{error}</p>
      </div>
    </section>
  );
};
