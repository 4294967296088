import { ENDPOINTS } from '../../consts/endpoints';
import { URLS } from '../../consts/urls';
import { UpdateProjectData } from '../../interfaces/projects';
import { projectUpdateDataAdapter } from '../adapters';
import { authorizatedFetcher } from './authorizatedFetcher';

export const updateProject = async (data: UpdateProjectData) => {
  await authorizatedFetcher(
    `${URLS.MANUSCRIPTIFY_API_URL}${ENDPOINTS.UPDATE_PROJECT}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(projectUpdateDataAdapter(data)),
    }
  );
};
