import Cookies from 'universal-cookie';
import { jwtDecode } from "jwt-decode";

export const getIsTokenExpired = () => {
  let cookies = new Cookies(null, { path: '/' });
  let bearer = cookies.get('token');  
  if (!!bearer) {
    let tokenInfo = jwtDecode(bearer);
    let expired = (Date.now() >= (tokenInfo.exp || 0) * 1000);
    console.log(`expired: ${expired}`);
    return expired;
  } else {
    console.log(`token not found`);
    return true;
  }
};
