export const FolderOpen = (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9374 1.75H9.84797C9.66301 1.7506 9.48204 1.69621 9.32805 1.59375L8.24211 0.867582C7.88251 0.627119 7.45947 0.499158 7.02688 0.500004H4.06242C3.48245 0.500625 2.92641 0.731292 2.51631 1.14139C2.10621 1.55149 1.87554 2.10753 1.87492 2.6875V3.625H18.1249C18.1249 2.41875 17.1437 1.75 15.9374 1.75ZM16.5527 15.5H3.44719C2.87321 15.4994 2.32248 15.2731 1.91378 14.8701C1.50507 14.4671 1.27114 13.9196 1.26242 13.3457L0.631955 6.91055V6.89961C0.611309 6.64169 0.64427 6.38229 0.728764 6.13772C0.813258 5.89316 0.947457 5.66873 1.12291 5.47856C1.29837 5.28839 1.51129 5.13659 1.74828 5.03272C1.98526 4.92885 2.24118 4.87515 2.49992 4.875H17.5038C17.7625 4.87526 18.0183 4.92903 18.2552 5.03295C18.4921 5.13687 18.705 5.28869 18.8803 5.47885C19.0557 5.66901 19.1898 5.8934 19.2743 6.13791C19.3587 6.38242 19.3917 6.64176 19.371 6.89961V6.91055L18.7374 13.3457C18.7287 13.9196 18.4948 14.4671 18.0861 14.8701C17.6774 15.2731 17.1266 15.4994 16.5527 15.5Z"
      fill="#A0FFF9"
    />
  </svg>
);
