import classNames from 'classnames';
import { useState } from 'react';
import { URLS } from '../../consts/urls';
import { CheckCircle } from '../icons/CheckCircle';
import { ChevronDown } from '../icons/ChevronDown';
import { ChevronUp } from '../icons/ChevronUp';
import { Button } from '../Button';

interface ReassuringMessageProps {
  folderId: string;
  quoteText: string;
  quoteAuthor: string;
  quoteBook?: string;
  quoteCharacter?: string;
  hidePanel: () => void;
}

export const ReassuringMessage = ({
  folderId,
  quoteText,
  quoteAuthor,
  quoteBook,
  quoteCharacter,
  hidePanel,
}: ReassuringMessageProps) => {
  const [expandPanel, setExpandPanel] = useState(true);

  return (
    <section className="m-5 flex rounded-lg border border-violet-3 py-4">
      <span className="mx-[14px] mt-[1px]">{CheckCircle}</span>
      <div className="mr-4 grow">
        <header
          className={classNames('flex justify-between', {
            'mb-4': expandPanel,
          })}
        >
          <span className="medium-14 grow">
            Your manuscript is being generated.
          </span>
          <button type="button" onClick={() => setExpandPanel((prev) => !prev)}>
            {expandPanel ? ChevronUp() : ChevronDown()}
          </button>
        </header>
        {expandPanel && (
          <>
            <p className="mb-4">
              <span>This may take a while. Check your</span>
              <button
                type="button"
                className="inline-block font-semibold underline dark:hover:text-primary-hover dark:active:text-primary-pressed"
                onClick={() => {
                  window.open(`${URLS.GOOGLE_DRIVE_FOLDERS}/${folderId}`, '_blank');
                }}
              >
                <span className="medium-14">manuscripts folder</span>
              </button>
              <span> in a few minues.</span>
            </p>
            <p className="mb-4">
              <blockquote className="mb-4">“{`${quoteText}`}”</blockquote>
              <span>&mdash;</span>
              {quoteCharacter && (
                  <span>{`${quoteCharacter}`} in </span>
              )}
              {quoteBook && (
                <>
                  <span style={{fontStyle: 'italic'}}>{`${quoteBook}`}</span>
                  <span> by </span>
                </>
              )}
              <span>{`${quoteAuthor}`}</span>
            </p>
            <div className="flex justify-end">
              <div>
                <Button type="secondary" onClick={hidePanel}>
                  Close notification
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
