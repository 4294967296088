import classNames from 'classnames';
import React from 'react';
import { Header } from './Header';
import { Exit } from './icons/Exit';
import { LogoWithText } from './icons/LogoWithText';

interface PanelLayoutProps {
  children: React.ReactNode;
}

export const PanelLayout = ({
  children,
}: PanelLayoutProps) => {
  return (
    <div
      className={classNames(`h-full w-full`)}
    >
      <div
        className={classNames(
          'h-full w-full bg-white text-sm font-light',
          'text-darkblue-1 dark:bg-darkblue-1 dark:text-white',
          'flex flex-col'
        )}
      >
        <Header
          leftIcon={<LogoWithText />}
        />
        {children}
      </div>
    </div>
  );
};
