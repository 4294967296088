interface NewFilesLabelProps {
  newFilesCount: number;
}

export const NewFilesLabel = ({ newFilesCount }: NewFilesLabelProps) => {
  if (newFilesCount === 0) {
    return null;
  }
  const newFileText =
    newFilesCount === 1 ? '1 new file' : `${newFilesCount} new files`;
  return (
    <div className="mr-4 rounded-2xl border border-violet-3 bg-violet-1 px-2">
      <span className="medium-10">{newFileText}</span>
    </div>
  );
};
