import { ENDPOINTS } from '../../consts/endpoints';
import { URLS } from '../../consts/urls';
import { UserProfileResponse } from '../../interfaces/api';
import { userProfileResponseAdapter } from '../adapters';
import { authorizatedFetcher } from './authorizatedFetcher';

export const getUserProfile = async () => {
  const response = await authorizatedFetcher(
    `${URLS.MANUSCRIPTIFY_API_URL}${ENDPOINTS.USER_PROFILE}`
  );
  const data: UserProfileResponse = await response.json();
  return userProfileResponseAdapter(data);
};
