import { ENDPOINTS } from '../../consts/endpoints';
import { URLS } from '../../consts/urls';
import { FolderTreeNode } from '../../interfaces/projects';
import { authorizatedFetcher } from './authorizatedFetcher';

export const getFolder = async (
  id: string | undefined,
  nodeType: 'folder' | 'project' = 'folder'
) => {
  if (!id) throw new Error('Folder id is not defined');

  const url = new URL(`${URLS.MANUSCRIPTIFY_API_URL}${ENDPOINTS.NODE_TREE}`);
  if (nodeType === 'project') url.searchParams.append('project_id', id);
  if (nodeType === 'folder') url.searchParams.append('folder_id', id);

  const response = await authorizatedFetcher(url.toString());
  const data: FolderTreeNode = await response.json();
  return data;
};
