import { Menu, Transition } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { Fragment } from 'react';
import { useNavigate } from 'react-router';
import { ROUTES } from '../consts/routes';
import { removeProject } from '../utils/api/removeProject';
import { queryClient } from '../utils/queryClients';
import { Document } from './icons/Document';
import { Elipsis } from './icons/Ellipsis';
import { Settings } from './icons/Settings';
import { TrashBin } from './icons/TrashBin';
import { Quicklink } from './Quicklink';

interface ProjectCardDropdownProps {
  projectId: string;
  showFilePath: () => void;
  hideFilePath: () => void;
  isPathShowed: boolean;
  deleteProject: (projectId: string) => void;
}

export const ProjectCardDropdown = ({
  projectId,
  showFilePath,
  hideFilePath,
  isPathShowed,
  deleteProject,
}: ProjectCardDropdownProps) => {
  const navigate = useNavigate();

  const menuItems = [
    {
      label: isPathShowed ? 'Hide file path' : 'Show file path',
      onClick: isPathShowed ? hideFilePath : showFilePath,
      icon: Document,
    },
    {
      label: 'Settings',
      onClick: () =>
        navigate(ROUTES.PROJECT_SETTINGS, { state: { projectId } }),
      icon: Settings,
    },
    {
      label: 'Remove',
      onClick: () => deleteProject(projectId),
      icon: TrashBin,
    },
  ];

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        className="flex h-6 w-6 items-center justify-center rounded-full hover:bg-violet-1 active:bg-violet-2"
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          e.stopPropagation()
        }
      >
        <Elipsis />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="ring-black absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-darkblue-1 px-5 pb-3 shadow-lg ring-1 ring-opacity-5 focus:outline-none">
          {menuItems.map((item) => (
            <Menu.Item key={item.label}>
              {() => (
                <div
                  className="mt-3"
                  onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                    e.stopPropagation()
                  }
                >
                  <Quicklink
                    label={item.label}
                    onClick={item.onClick}
                    icon={() => item.icon}
                    iconPosition="left"
                  />
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
