import { ReassuringMessage } from './ReassuringMessage';
import { Detail } from './Detail';
import { ROUTES } from '../../consts/routes';
import { Navbar } from '../Navbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getFolder } from '../../utils/api/getFolder';
import { TipsPanel } from '../TipsPanel';
import { Quicklink } from '../Quicklink';
import { ArrowBackCircle } from '../icons/ArrowBackCircle';
import { ErrorPanel } from '../ErrorPanel';
import { getIsTokenExpired } from '../../utils/getIsTokenExpired';
import { useState } from 'react';
import { generateManuscript } from '../../utils/api/generateManuscript';
import { Spinner } from '../icons/Spinner';
import { DragCursor } from '../icons/DragCursor';
import { PROJECT_FOLDER } from '../../consts/queryKeys';
import { PROJECT_TIP_KEY } from '../../consts/localStorage';
import { Login } from '../Login';

export const Project = () => {
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [isPendingVisible, setIsPendingVisible] = useState(false);
  const location = useLocation();
  const [projectId, title]: string[] = location.state.split('~', 2);

  const navigate = useNavigate();

  const { data, isPending, isError } = useQuery({
    queryKey: [PROJECT_FOLDER, projectId],
    queryFn: () => getFolder(projectId, 'project'),
  });

  const {
    mutate,
    isSuccess: isMutationSuccess,
    isError: isMutationError,
    data: mutationData,
    isPending: isMutationLoading,
  } = useMutation({
    mutationFn: generateManuscript,
    onMutate: () => {
      setIsPendingVisible(true);
    },
    onSuccess: () => {
      setIsPanelVisible(true);
    },
    onSettled: () => {
      setIsPendingVisible(false);
    },
  });

  const hidePanel = () => {
    setIsPanelVisible(false);
  };

  const handleGenerateManuscript = async (ids: string[], isFinal: boolean) => {
    mutate({ projectId, folderIds: ids, isFinal });
  };

  if (isPending) {
    return (
      <>
        <Navbar
          title={title}
          quicklinkLabel="Projects"
          redirectTo={ROUTES.PROJECTS}
        />
        <TipsPanel tipContent={Tip} id={PROJECT_TIP_KEY} />
        <div className="mt-12 flex justify-center">
          <Spinner />
        </div>
      </>
    );
  }

  if (isError) {
    if (getIsTokenExpired()) {
      window.location.replace(ROUTES.ROOT);
    }
    return <ErrorPanel error='Error unrelated to token expiry.' />
  }

  return (
    <>
      {isPendingVisible && (
        <div className="absolute z-max flex h-full w-full items-center justify-center bg-darkblue-1 opacity-50">
          <Spinner />
        </div>
      )}
      <Navbar
        title={title}
        quicklinkLabel="Projects"
        redirectTo={ROUTES.PROJECTS}
      />
      <TipsPanel tipContent={Tip} id={PROJECT_TIP_KEY} />
      {isMutationError && (
        <ErrorPanel error="Sorry, we were unable to generate the manuscript." />
      )}
      <div className="w-full self-end">
        {isMutationSuccess && isPanelVisible && (
          <ReassuringMessage
            folderId={mutationData.id}
            quoteText={mutationData.quote_text}
            quoteAuthor={mutationData.quote_author}
            quoteBook={mutationData.quote_book}
            quoteCharacter={mutationData.quote_character}
            hidePanel={hidePanel}
          />
        )}
      </div>

      {data.children && data.children.length > 0 ? (
        <Detail
          generateManuscript={handleGenerateManuscript}
          isMutationLoading={isMutationLoading}
          treeNode={data}
          projectId={projectId}
          showLoading={() => setIsPendingVisible(true)}
          hideLoading={() => setIsPendingVisible(false)}
        />
      ) : (
        <div className="m-5">
          <p>It seems there's no writing in this project yet.</p>
        </div>
      )}
    </>
  );
};

const Tip = (
  <p>
    Here you can organise the content of your writing folder. By
    using the
    <span className="-mt-[3px] inline-block px-[6px] align-middle">
      {DragCursor}
    </span>
    icon you can drag the files to move or re-order them.
  </p>
);
