import { Switch } from '@headlessui/react';

interface SwitchProps {
  onChange: () => void;
  checked: boolean;
}

export default function ({ onChange, checked }: SwitchProps) {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className={`${!checked ? 'bg-violet-3' : 'bg-primary'}
          relative inline-flex h-[20px] w-[36px] shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <div
        aria-hidden="true"
        className={`${
          !checked ? 'translate-x-0' : 'translate-x-4 bg-darkblue-1'
        }
            pointer-events-none m-[2px] inline-block h-[16px] w-[16px] transform rounded-full bg-white ring-0 transition duration-300 ease-in-out`}
      />
    </Switch>
  );
}
