export const ArrowForwardCircle = (color: string) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.125 9C17.125 4.51289 13.4871 0.875 9 0.875C4.51289 0.875 0.875 4.51289 0.875 9C0.875 13.4871 4.51289 17.125 9 17.125C13.4871 17.125 17.125 13.4871 17.125 9ZM8.81836 12.5687C8.76008 12.5109 8.71375 12.4422 8.68203 12.3665C8.65031 12.2908 8.63382 12.2096 8.63349 12.1275C8.63316 12.0454 8.64901 11.964 8.68013 11.8881C8.71125 11.8121 8.75702 11.743 8.81484 11.6848L10.8586 9.625H5.64062C5.47486 9.625 5.31589 9.55915 5.19868 9.44194C5.08147 9.32473 5.01562 9.16576 5.01562 9C5.01562 8.83424 5.08147 8.67527 5.19868 8.55806C5.31589 8.44085 5.47486 8.375 5.64062 8.375H10.8586L8.81484 6.31523C8.75703 6.25691 8.71127 6.18777 8.68018 6.11176C8.64909 6.03575 8.63327 5.95436 8.63364 5.87224C8.634 5.79012 8.65053 5.70887 8.6823 5.63314C8.71406 5.55741 8.76042 5.48867 8.81875 5.43086C8.87708 5.37305 8.94622 5.32729 9.02223 5.2962C9.09824 5.26511 9.17962 5.24929 9.26175 5.24965C9.4276 5.25039 9.58637 5.31697 9.70312 5.43477L12.8043 8.55977C12.9205 8.67683 12.9857 8.83507 12.9857 9C12.9857 9.16493 12.9205 9.32316 12.8043 9.44023L9.70312 12.5652C9.6453 12.6236 9.57653 12.6701 9.50075 12.7018C9.42496 12.7336 9.34365 12.7502 9.26147 12.7505C9.17929 12.7508 9.09785 12.7349 9.02182 12.7038C8.94578 12.6726 8.87664 12.6267 8.81836 12.5687Z"
      fill={color}
    />
  </svg>
);
