/// <reference path="../interfaces/declarations.d.ts" />
import GoogleButton from 'react-google-button';
import Cookies from 'universal-cookie';
import { URLS } from '../consts/urls';

export const Login = () => {
  const handleGoogleClick = async () => {
    const sid = crypto.randomUUID();
    const cookies = new Cookies();
    cookies.set('sid', sid, { path: '/' });
    window.open(`${URLS.MANUSCRIPTIFY_API_URL}/g1?sid=${sid}`, '_self');
  };

  return (
    <div className="h-full w-80 flex flex-col mx-auto p-5 bg-white font-light text-darkblue-1 dark:bg-darkblue-1 dark:text-white">
      <section className="h-full mt-3 flex flex-col items-center px-5">
        <p className="mt-auto">
          In order to help you generate awesome manuscripts, Manuscriptify needs to
          perform actions using your Google account.
        </p>
        <div className="mt-8 mb-auto">
          <GoogleButton
            onClick={handleGoogleClick}
            className="google-button"
          />
        </div>
      </section>
    </div>
  );
};
