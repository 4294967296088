import {
  CreateProjectDataRequest,
  MoveFileDataRequest,
  ProjectSettingsResponse,
  ProjectsResponse,
  UpdateProjectDataRequest,
  UserProfileResponse,
  SaveUserProfileResponse,
} from '../interfaces/api';
import { MoveFileData } from '../interfaces/folderTree';
import {
  CreateProjectData,
  Project,
  UpdateProjectData,
} from '../interfaces/projects';
import {
  UserProfile,
  SaveUserProfile
} from '../interfaces/userProfile';

export const projectResponseAdapter = (projects: ProjectsResponse): Project[] =>
  projects.projects.map((project) => ({
    filePath: project.file_path,
    newFilesCount: project.new_files,
    projectId: project.project_id,
    title: project.title,
  }));

export const projectCreateDataAdapter = (
  project: CreateProjectData
): CreateProjectDataRequest => {
  return {
    project_id: project.projectId,
    title: project.title,
    shortname: project.shortTitle,
    pseudonym: project.pseudonym,
    genre: project.genre,
    category: project.category,
    chapter_depth: project.chapterDepth,
  };
};

export const moveFileDataAdapter = ({
  insertAfterId,
  itemId,
  parentId,
  projectId,
}: MoveFileData): MoveFileDataRequest => {
  return {
    project_id: projectId,
    insert_after_id: insertAfterId,
    item_id: itemId,
    parent_id: parentId,
  };
};

export const projectSettingsResponseAdapter = (
  projectSettings: ProjectSettingsResponse
): UpdateProjectData => ({
  projectId: projectSettings.project_id,
  title: projectSettings.title,
  shortTitle: projectSettings.title,
  pseudonym: projectSettings.pseudonym,
  genre: projectSettings.genre,
  category: projectSettings.category,
  chapterDepth: projectSettings.chapter_depth,
});

export const projectUpdateDataAdapter = (
  project: UpdateProjectData
): UpdateProjectDataRequest => ({
  project_id: project.projectId,
  title: project.title,
  pseudonym: project.pseudonym,
  genre: project.genre,
  category: project.category,
  chapter_depth: project.chapterDepth,
  shortname: project.shortTitle,
});

export const userProfileResponseAdapter = (
  userProfile: UserProfileResponse
): UserProfile => ({
  interval: userProfile.interval,
  expiry: userProfile.expiry,
  autorenew: userProfile.autorenew,
  phone: userProfile.phone,
  replyEmail: userProfile.reply_email,
  replyTo: userProfile.reply_to,
  street1: userProfile.street1,
  street2: userProfile.street2,
  defaultCategory: userProfile.default_category,
  defaultChapterDepth: userProfile.default_chapter_depth,
  defaultGenre: userProfile.default_genre,
  defaultPseudonym: userProfile.default_pseudonym,
});

export const userProfileAdapter = (
  userProfile: SaveUserProfile
): SaveUserProfileResponse => ({
  autorenew: userProfile.autorenew,
  phone: userProfile.phone,
  reply_email: userProfile.replyEmail,
  reply_to: userProfile.replyTo,
  street1: userProfile.street1,
  street2: userProfile.street2,
  default_category: userProfile.defaultCategory,
  default_chapter_depth: userProfile.defaultChapterDepth,
  default_genre: userProfile.defaultGenre,
  default_pseudonym: userProfile.defaultPseudonym,
});
