export const CaretRight = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.1875 1.375L6.8125 7L1.1875 12.625"
      stroke="#6462B2"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
