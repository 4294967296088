import { useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { Exit } from './icons/Exit';
import { InformationCircle } from './icons/InformationCircle';

interface TipsPanelProps {
  tipContent: React.ReactNode;
  id: string;
}

export const TipsPanel = ({ tipContent: TipContent, id }: TipsPanelProps) => {
  const [userSawPanel, setUserSawPanel] = useLocalStorage(id, false);
  const [showPanel, setShowPanel] = useState(true);
  const handleClosePanel = async () => {
    setShowPanel(false);
    setUserSawPanel(true);
  };

  if (!showPanel || userSawPanel) {
    return null;
  }

  return (
    <section className="m-5 mb-3 flex rounded-lg border border-violet-3 py-4">
      <span className="mx-[14px] mt-[1px]">
        <InformationCircle />
      </span>
      <div className="mr-4">
        <header className="mb-2 flex justify-between">
          <span className="medium-14">Some tips for you:</span>
          <button type="button" onClick={handleClosePanel}>
            {Exit}
          </button>
        </header>
        {TipContent}
      </div>
    </section>
  );
};
